.route-section {
  margin-left: -16px;
  margin-right: -16px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 24px 16px;

  @media screen and (max-width: 360px) {
    margin-left: -12px;
    margin-right: -12px;
  }

  &__origin,
  &__destination {
    color: var(--color-gray-500);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    flex: 1 0;
  }

  &__destination {
    text-align: right;
  }

  &__arrow {
    margin-left: 24px;
    margin-right: 24px;
  }
}
