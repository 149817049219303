.mobea-time-picker {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  touch-action: none;

  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.48);
  }

  &__content {
    position: absolute;
    top: 64px;
    left: 16px;
    bottom: 16px;
    right: 16px;
    background-color: white;
    display: flex;
    flex-direction: column;
  }

  &__header {
    position: relative;
    padding: 24px;
    flex-shrink: 0;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
    color: var(--color-primary);
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    z-index: 1;

    &__close-icon {
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__pick-area {
    position: relative;
    flex-grow: 1;
    display: flex;
    align-items: center;
    font-size: 48px;
    letter-spacing: 1px;
    line-height: 56px;
    overflow: hidden;

    &__hours,
    &__minutes {
      width: calc(50% - 20px);
    }

    &__minutes {
      right: 0;
    }

    &__separator {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-family: var(--font-family-numbers);
    }

    &__hours-selection,
    &__minutes-selection {
      position: absolute;
      height: 80px;
      width: calc(50% - 70px);
      border: 1px solid var(--color-primary);
      border-radius: 8px;
      pointer-events: none;
    }

    &__hours-selection {
      left: 24px;
    }

    &__minutes-selection {
      right: 24px;
    }

    &__top-cover,
    &__bottom-cover {
      position: absolute;
      left: 0;
      right: 0;
      height: calc(100% / 2 - 40px);
      background: rgba(255, 255, 255, 0.8);
      pointer-events: none;
    }

    &__top-cover {
      top: 0;
    }

    &__bottom-cover {
      bottom: 0;
    }
  }

  &__action {
    padding: 32px;
    box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.25);
  }

  .scrollable-numbers {
    flex: 1 0;
    max-height: 100%;
    position: absolute;
    top: 0;

    &__container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    &__number {
      font-family: var(--font-family-numbers);
      font-size: 36px;
      letter-spacing: 1px;
      line-height: 56px;
      text-align: center;
      padding: 16px 0;
      transition: font-size 0.2s;

      &.active {
        font-size: 48px;
      }
    }
  }
}
