@import "styles/maps";

.mobea-find-bike {
 

  .search-location-field-container {
    @include search-location-field-container();

    margin: 12px 24px;
  }

  &__controls {
    position: relative;
    padding: 8px;

    &__location-ring {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;

      &__location {
        display: flex;
        align-items: flex-start;
        max-width: 55%;
        color: var(--color-gray-300);
        font-family: var(--font-family-numbers);
        font-size: 14px;
        line-height: 20px;

        svg {
          flex-shrink: 0;
        }

        > span {
          margin-left: 8px;
        }
      }

      &__ring {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        color: var(--color-primary);
        font-size: 14px;
        max-width: 50%;

        > span {
          margin-left: 4px;
          margin-top: 2px;
        }
      }
    }

    &__code-report {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__code {
        display: flex;
        align-items: flex-start;
        color: var(--color-gray-400);
        font-family: var(--font-family-numbers);
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 20px;

        svg {
          flex-shrink: 0;
        }

        > span {
          margin-left: 12px;
        }
      }

      &__report {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        margin-left: 24px;
        color: var(--color-primary);
        font-size: 14px;
        max-width: 50%;

        > span {
          margin-left: 4px;
          margin-top: 4px;
        }
      }
    }

    &__actions {
      margin-top: 24px;
      display: flex;
      align-items: center;

      .mobea__button {
        &:not(:first-of-type) {
          margin-left: 16px;
        }
      }
    }
  }

  &__pause-ride {
    .mobea__close-button:before,
    .mobea__close-button:after {
      height: 8%;
    }

    .mobea__spinner {
      position: relative;
    }
  }

  &.mobea__map-intent__continue {
    .m_page__header__subtitle {
      color: var(--color-error);
    }
  }

  .mobea__mobit-selected-marker {
    /* offset icon to center */
    margin-top: -20px;
    margin-left: -20px;
  }
}

.mobea__here-map {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  box-shadow: 0 2px 4px 0 inset rgba(0, 0, 0, 0.28);
}
