.provider-terms-conditions {
  .mobea__provider-logo {
    display: flex;
    height: 32px;
    margin: 8px auto 0;
  }

  &__page__content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }

  &__main {
    display: flex;
    flex-direction: column;
    background: var(--color-background);
    flex: 1 1 auto;
    margin: 24px -32px -32px;
    padding: 32px;

    &__title {
      text-align: center;
      font-size: 32px;
      font-weight: 500;
    }

    &__description {
      text-align: center;
      color: var(--color-gray-400);
      font-size: 16px;
      line-height: 24px;
      width: 295px;
      margin: 12px auto 0;
    }

    &__spacer {
      flex-grow: 1;
    }

    &__checkbox {
      input + label > svg {
        top: 0;
        transform: none;
      }
    }

    .mobea__button {
      margin-top: 16px;
    }
  }
}
