.provider-details {
    position: relative;
    padding: 12px 24px 0;

    &__address-button {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__address {
        display: flex;
        align-items: center;

        svg {
          flex-shrink: 0;
        }

        &__label {
          position: relative;
          margin-left: 6px;
          color: var(--color-gray-300);
          font-family: var(--font-family-numbers);
          font-size: 14px;
          line-height: 20px;
        }
      }

      .route-directions-button {
        margin-left: 8px;
        flex-shrink: 0;
      }
    }

    &__distance-price {
      margin-top: 16px;
      display: flex;
      align-items: center;
    }

    &__distance {
      display: flex;
      align-items: center;

      &__text {
        margin-left: 8px;
        color: var(--color-gray-300);
        font-family: var(--font-family-numbers);
        font-size: 14px;
      }
    }

    &__price {
      margin-left: 2px;
      display: flex;
      align-items: center;
      margin-left: 30px;

      &__name {
        margin-left: 8px;
        color: var(--color-gray-300);
        font-family: var(--font-family-secondary);
        font-size: 14px;
      }

      &__price {
        margin-left: 8px;
        color: var(--color-gray-400);
        font-family: var(--font-family-numbers);
        font-size: 14px;
        font-weight: bold;
      }
    }

    .separator {
      margin: 16px 0;
      height: 1px;
      background-color: var(--color-background);
    }

    &__description {
      color: var(--color-gray-300);
      font-family: var(--font-family-secondary);
      font-size: 14px;
      line-height: 20px;
    }

    &__actions {
      display: flex;
      align-items: center;

      

      .mobea__button {
        margin-top: 32px;

        &:not(:first-of-type) {
          margin-left: 12px;
        }
      } 
      
      @media screen and (max-width: 360px) {
        display: block;

        .mobea__button {
          margin-top: 16px;

          &:not(:first-of-type) {
            margin-left: 0;
            margin-top: 12px;
          }
        }
      }
    }

    > .mobea__spinner {
      margin-top: 32px;
    }

    &__footer{
        color: var(--color-gray-300);
        font-family: var(--font-family-numbers);
        font-size: 12px;
        line-height: 20px;
        padding-top: 16px;
    }
  }