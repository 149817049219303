.mobea-history__costs__spending {
  .mobea__travel-pass__header__type {
    width: 32px;
    height: 32px;

    > svg {
      width: 50%;
      height: 50%;
    }
  }
}
