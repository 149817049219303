@import "styles/constants.scss";

.mobea-welcome {
  @include fullscreen-page;
  height: 100%;
  background-color: #031d4c;
  background: url("/static/images/welcome_bg.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &.loader {
    z-index: 2000;
  }

  &__start {
    width: 340px;
    max-width: calc(100% - 20px);
    background-color: rgba(255, 255, 255, 0.88);
    margin: 0 auto;
    padding: 24px 24px 32px;
    margin-top: 100px;

    @media screen and (min-width: 400px) {
      width: 360px;
    }

    &__brand {
      margin: 0 auto 24px;
      text-align: center;
      width: 80%;     
    }

    &__text {
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      margin: 24px 0;
    }

    &__wrapper {
      display: flex;
      flex: 1 1 auto;
      justify-content: center;
      flex-direction: column;
    }
  }

  .mobea__language {
    flex: 0 0 auto;
  }
}
