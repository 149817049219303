.mobea__dropdown-group {
  height: 51px;
  position: relative;
  padding-top: 14px;

  > label {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 13px;
    line-height: 24px;
    color: var(--color-gray-200);
    font-family: var(--font-family-secondary);
    font-weight: 500;
    height: 14px;
  }

  .mobea__dropdown {
    width: 100%;

    &__select {
      border-bottom: 1px solid var(--color-gray-100);
      height: 36px;
      padding-top: 4px;

      &__arrow {
        right: 8px;
        color: var(--color-gray-200);
        top: 20%;
      }

      &__value {
        line-height: 32px;
        font-size: 16px;
        font-family: var(--font-family-secondary);
        height: 100%;
        display: inline-block;
      }

      &__placeholder {
        color: var(--color-gray-200);
        display: inline-block;
      }
    }

    &__options {
      border: 0 none;

      &.dropdown-enter-done,
      &.dropdown-enter-active,
      &.dropdown-exit-active {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.48);
      }
    }

    &__option {
      padding: 8px 12px;
      font-size: 16px;
      line-height: 24px;
      font-family: var(--font-family-secondary);
      font-weight: 500;
      color: var(--color-gray-500);
    }
  }
}
