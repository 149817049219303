.mobea__map-offline {
  position: absolute;
  top: 150%;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: var(--color-background);
  padding: 0 32px;
  transition: top 500ms ease-in-out;

  &.shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08) inset;
  }

  &.opened {
    top: 0;
  }

  &__header {
    margin-top: 8px;
    height: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    > h1 {
      font-size: 22px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  &.incident {
    .mobea__map-offline__header {
      margin-top: 24px;
    }
  }

  &__content {
    flex: 0 0 auto;
    text-align: center;
    color: var(--color-gray-300);

    &__icon {
      margin-bottom: 24px;

      > svg {
        width: 88px;
        height: 88px;
        color: var(--color-gray-100);
      }
    }

    &__headline {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      margin: 0;
      margin-bottom: 8px;
    }

    &__text {
      margin: 0;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
