.date-time-price {
  margin-left: -16px;
  margin-right: -16px;
  background-color: white;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px 16px 16px;

  @media screen and (max-width: 360px) {
    margin-left: -12px;
    margin-right: -12px;
  }

  &__section.fined {
    color: var(--color-error);
  }

  &__section.fined {
    color: var(--color-error);
  }

  &__label-icon {
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
      color: var(--color-gray-200);
    }

    label {
      margin-left: 4px;
      color: var(--color-gray-200);
      font-family: var(--font-family-secondary);
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__value {
    margin-top: 4px;
    font-family: var(--font-family-numbers);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;

    &.finished,
    &.cancelled {
      font-weight: bold;
    }
  }
}
