.mobea__provider-marker {
  $size: 28px;
  $svgSize: 16px;
  $clusterNumberSize: 18px;
  $selectedSize: 48px;
  $selectedSvgSize: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: $size;
  width: $size;
  border-radius: 50%;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.24);
  transition: height 0.2s, width 0.2s, margin-top 0.2s, margin-left 0.2s,
    border-color 0.2s;
  margin-top: -$size / 2;
  margin-left: -$size / 2;

  .cluster-number {
    position: absolute;
    height: $clusterNumberSize;
    width: $clusterNumberSize;
    top: -9px;
    right: -9px;
    background: white;
    border: 1px solid;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-family: "Arial";
  }

  svg {
    width: $svgSize;
    height: $svgSize;
    transition: height 0.2s, width 0.2s;
    pointer-events: none;
    position: relative;
    z-index: 1;
  }

  &.disabled {
    > .background {
      filter: contrast(0.5) brightness(1.2);
    }
  }

  &.selected {
    height: $selectedSize;
    width: $selectedSize;
    margin-top: -$selectedSize / 2;
    margin-left: -$selectedSize / 2;

    svg {
      width: $selectedSvgSize;
      height: $selectedSvgSize;
    }
  }
}

@mixin search-location-field-container {
  position: relative;
  border: 1px solid var(--color-gray-100);
  border-radius: 2px;
  background: white;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);

  .mobea__input-group {
    float: none;
    position: relative;
    border-bottom: none;
    padding: 4px 8px;
    height: auto;
  }
}
