.mobea-bottom-slider {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 24px 24px 0 0;
  background-color: #fff;
  box-shadow: 0 -4px 12px 0 rgba(0, 0, 0, 0.08);
  z-index: 1;
  touch-action: none;
  transition: bottom 0.2s;

  &__drag-area {
    position: absolute;
    width: 100%;
    min-height: 60px;

    &.title-visible {
      position: relative;
      min-height: auto;
    }
  }

  &__touch-marker {
    width: 48px;
    //border-radius: 2px;
    //background-color: var(--color-gray-100);
    margin: 12px auto;
    transition: height 0.2s, margin 0.2s;

    &.hidden {
      opacity: 0;
    }
  }

  &__title {
    text-align: center;
    font-family: var(--font-family-numbers);
    font-size: 20px;
  }

  &__back-icon {
    position: absolute;
    top: 24px;
    left: 24px;
    opacity: 0.7;
  }

  &__close-icon {
    position: absolute;
    top: 24px;
    right: 24px;
    opacity: 0.7;
  }

  &__content {
    margin-top: 54px;
    padding: 16px;

    &.title-visible {
      margin-top: 0;
    }
  }
}
