.mobea-request-pin {
  &__header {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      max-width: 50%;
      height: 56px;
    }
  }

  &__content {
    &__headline {
      font-size: 24px;
      font-weight: 600;
      line-height: 34px;
      text-align: center;
      margin-bottom: 24px;
    }

    &__text {
      max-width: 500px;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: var(--color-gray-400);
      margin: 0 auto 32px;
    }

    .mobea__phone-number-picker {
      margin-bottom: 32px;
    }
  }
}
