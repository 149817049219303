.mobea__android-install {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 138px;
  padding: 16px;
  box-shadow: 0px 2px 5px #000;
  z-index: 1;
  background-color: white;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.48);
  }

  &__header {
    display: flex;

    &__logo {
      flex: 0 0 44px;
      padding: 7px;
      height: 44px;
      background-color: var(--color-background);
      border-radius: 2px;
      width: 44px;

      > img {
        max-width: 32px;
        max-height: 32px;
        width: 44px;
        height: 44px;
      }
    }

    &__text {
      flex: 1 1 auto;
      margin-left: 16px;

      h2 {
        margin: 0;
        line-height: 30px;
        font-size: 20px;
        font-weight: 500;
        height: 30px;
      }

      p {
        font-size: 13px;
        line-height: 14px;
        margin: 0;
      }
    }
  }

  &__close {
    flex: 0 0 29px;
  }

  &__add {
    float: right;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-text-inverted);
    font-weight: 500;
    text-align: center;
    background-color: var(--color-primary);
    padding: 8px 18px 6px;
    margin-top: 27px;
    border: 0 none;
    border-radius: 3px;
    letter-spacing: 0.5px;
    text-decoration: none;
    &::first-letter{
      text-transform: uppercase;
    }
  }
}
