.mobea__nmbs-ticket {
  &__scanners {
    margin-top: 16px;
    transition: all 0.5s;

    &__timer {
      font-family: var(--font-family-numbers);
      font-size: 16px;
      letter-spacing: 5px;
      line-height: 24px;
      text-align: center;
      margin-bottom: 12px;
    }

    &__qr-code {
      margin-bottom: 20px;
      transition: height 0.1s;

      > img {      
        image-rendering: pixelated;
        margin: 0 auto;
        display: block;
        position: relative;
        z-index: 1;
        transform-origin: top;
        max-height: 300px;
        transition: width 0.5s, height 0.5s, transform 0.1s;
      }
    }
  }

  &__journey {
   

    &__info {
      margin: 12px 0;
      overflow: auto;

      &.basic {
        .mobea__nmbs-ticket__item {
          float: left;
          width: 50%;
        }
      }
    }
  }

  &__hr-line {
    margin: 12px 0;
    background-color: var(--color-background);
    border: 0 none;
    height: 1px;
    clear: both;
  }

  &__item {
    font-family: var(--font-family-secondary);
    font-size: 12px;
    line-height: 20px;

    &__label,
    &__value {
      margin: 0;
    }

    &__label {
      line-height: 16px;
      color: var(--color-gray-200);

      > svg {
        vertical-align: sub;
        margin-right: 2px;
      }
    }

    &__label.column,
    &__value.column {
      float: left;
      width: 50%;
    }

    &__label.inline,
    &__value.inline {
      float: left;
    }
  }

  .ticket-icon {
    .mobea__navigation__active {
      opacity: 0;
    }
  }

  .mobea__nmbs-ticket__scanners__timer,
  .mobea__nmbs-ticket__journey__info,
  .mobea__nmbs-ticket__hr-line,
  .mobea__nmbs-ticket__security
   {
    max-height: 300px;
    transition: all 0.5s;
  }

  &.mobea__ticket-preview {
    .mobea__nmbs-ticket__scanners,
    .mobea__nmbs-ticket__journey {
      opacity: 0.48;
    }

    .mobea__nmbs-ticket__scanners__qr-code > img {
      width: 112px;
      height: 112px;
    }

    .mobea__nmbs-ticket__scanners__timer,
    .mobea__nmbs-ticket__journey__info,
    .mobea__nmbs-ticket__hr-line,
    .mobea__nmbs-ticket__security {
      opacity: 0;
      max-height: 0;
      margin: 0;
    }
  }
}
