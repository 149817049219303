.mobea__standard-error-dialog {
  svg {
    color: var(--color-error);
    width: 37px;
    height: 37px;
    margin-top: 26px;
  }

  .mobea__modal__dialog {
    &__header {
      padding-bottom: 16px;
    }

    &__image {
      width: 88px;
      height: 88px;
      margin-bottom: 16px;
      text-align: center;
    }

    &__content{
      margin-bottom: 8px;
    }
  }

  & .mobea__error .mobea__modal__dialog__header__headline {
    color: var(--color-secondary);
  }
}
