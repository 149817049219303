.mobea-providers-filter {
  position: relative;
  display: flex;
  padding-bottom: 12px;
  padding-right: 8px;
  padding-top: 4px;
  overflow-x: auto;
  z-index: 1;

  &__option {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 8px;
    padding: 10px 12px 10px 8px;
    border-radius: 16px;
    height: 32px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
    background-color: white;
    transition: background-color 0.2s;

    &__icon {
      margin-right: 4px;
      width: 16px;
      height: 16px;
      color: var(--color-gray-300);
      transition: color 0.2s;
    }

    &__name {
      color: var(--color-gray-300);
      font-family: var(--font-family-secondary);
      font-size: 12px;
      transition: color 0.2s;
      white-space: nowrap;
    }

    &.selected {
      background-color: var(--color-primary);
      color: var(--color-text-inverted);

      .mobea-providers-filter__option__icon {
        color: inherit;
      }

      .mobea-providers-filter__option__name {
        color: inherit;
      }
    }
  }
}
