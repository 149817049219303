.mobea-mobit-scan {
  &__content {
    margin: 0 -24px -32px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }

  &__text {
    padding: 0 32px;
    text-align: center;
    margin-bottom: 16px;
    flex: 0 0 auto;
    min-height: 76px;

    @media screen and (max-height: 700px) {
      margin-bottom: 12px;
    }

    @media screen and (max-width: 360px) {
      padding: 0 16px;
    }

    &__icon {
      width: 24px;
      height: 24px;
      margin-bottom: 8px;
      color: var(--color-gray-100);

      &__success {
        color: var(--color-mobit);
      }

      &__error {
        color: var(--color-error);
      }
    }

    &__desc {
      color: var(--color-gray-400);
      margin: 0;
      font-size: 14px;
      line-height: 20px;
      min-height: 40px;

      &.success {
        color: var(--color-mobit);
      }

      &.error {
        color: var(--color-error);
      }
    }
  }

  &__preview {
    background: url("/static/images/mobit-qr-bg.jpg");
    flex: 1 1 auto;
    position: relative;
    overflow: hidden;
    background-position: center;
    background-size: cover;

    &.white {
      background: white;
    }

    > video {
      min-height: 100%;
      min-width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &.hidden {
        visibility: hidden;
      }
    }

    &__overlay {
      position: absolute;
      z-index: 1;
      width: 66%;
      height: 0;
      border: 4px solid white;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      box-shadow: 0 0 0 999px rgba(0, 0, 0, 0.4);
      padding-top: 66%;

      &.success {
        border-color: var(--color-mobit);
        background-color: rgba(255, 255, 255, 0.47);
        box-shadow: 0 0 0 999px rgba(255, 255, 255, 0.47);
      }

      &.error {
        border-color: var(--color-error);
      }
    }

    &__enable-button {
      width: 80% !important;
      display: block;
      position: absolute;
      left: 10%;
      top: calc(50% - 40px);
    }

    &__controls {
      width: 100%;
      height: 56px;
      position: absolute;
      bottom: 24px;
      display: flex;
      justify-content: space-evenly;
      z-index: 10;

      @media screen and (max-height: 700px) {
        bottom: 16px;
        height: 48px;
      }

      @media screen and (max-width: 360px) {
        bottom: 8px;
      }

      &__button {
        width: 56px;
        height: 56px;
        border: 0 none;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
        display: block;
        padding: 0;
        font-family: var(--font-family-numbers);
        font-size: 14px;
        font-weight: 600;
        line-height: 56px;
        text-align: center;

        @media screen and (max-height: 700px) {
          height: 48px;
          width: 48px;
          line-height: 48px;
        }

        > svg {
          width: 32px;
          height: 32px;
          margin-top: 12px;

          @media screen and (max-height: 700px) {
            width: 30px;
            height: 30px;
            margin-top: 8px;
          }
        }

        &:disabled {
          background-color: var(--color-gray-100);
        }
      }
    }
  }
}
