.mobea__provider-tile {
  width: 100px;
  height: 120px;
  background-color: white;
  position: relative;
  border-radius: 2px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 360px) {
    width: 92px;
  }

  &__stripe {
    width: 100%;
    height: 6px;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    background-color: currentColor;
    border: 0 none;
  }

  &__logo {
    height: 40px;
    position: relative;
    top: 16px;
    margin: 0 auto;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    margin-bottom: 20px;

    &__default {
      text-decoration: none;
      display: block;
      width: 40px;
      height: 40px;
      background-color: var(--color-background);
      border-radius: 50%;
      overflow: hidden;
      text-indent: 100px;
      position: relative;
      top: -2px;

      &:after,
      &:before {
        content: " ";
        position: absolute;
        background-color: var(--color-gray-100);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }

      &:before {
        width: 3px;
        height: 16px;
      }

      &:after {
        width: 16px;
        height: 3px;
      }
    }
  }

  &__text {
    width: 100%;
    height: 56px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &__name {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      margin: 0;

      @media screen and (max-width: 360px) {
        font-size: 15px;
      }
    }

    &__description {
      font-size: 10px;
      line-height: 20px;
      margin: 0;
      font-family: var(--font-family-secondary);
      color: var(--color-gray-300);
    }
  }

  svg,
  img {
    max-height: 36px;
    max-width: 90%;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
  }

  svg{
    height: 28px;
  }

  &__lime svg {
    width: 70px;
    height: auto;    
  }

  &__mivb svg {
    height: 33px;
    width: 33px;
  }

  &__tec svg {
    width: 47px;
    height: 16px;
  }

  &__uber svg {
    width: 46px;
    height: 20px;
  }

  &__verts svg {
    width: 51px;
    height: 23px;
  }

  &__billy-bike img {
    height: 28px;
  }

  &__bird img {
    height: 16px;
  }

  &__felyx img{
    max-height: 30px;
  }

  &__deskalot svg{
    height: 32px;
  }

  &__dott svg {
    height: 22px;
    width: 66px;
  }

  &__li-bia-velo img {
    height: 40px;
    max-height: 40px;
  }

  &__poppy img {
    width: 60px;
  }

  &__velo-antwerpen img {
    height: 26px;
  }

  &__villo img {
    height: 30px;
  }

  &__wheels img {
    width: 80px;
  }
  
  &__blue-bike img{
    height: 36px;
  }
}
