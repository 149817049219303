@import "styles/constants";

.mobea-conditions {
  @include fullscreen-page;
  height: 100%;
  background-color: #fff;
  padding: 32px 24px;

  &__header {
    width: 100%;
    position: relative;
    margin-bottom: 32px;

    > h1 {
      color: var(--color-primary);
      font-size: 22px;
      font-weight: 500;
      line-height: 28px;
      text-align: center;
      padding-left: 28px;
    }

    &__back {
      position: absolute;
      left: 0;
      top: 0;
      background: none;
      border: 0 none;
      color: var(--color-primary);
      display: inline-block;
      height: 100%;
      padding: 2px 4px;
      outline: 0 none;
    }
  }

  &__content {
    > section {
      margin-bottom: 10px;
      overflow: auto;
    }
    h2 {
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
      margin: 16px 0;
      line-height: 28px;
    }
    p,
    li {
      margin-bottom: 8px;
      font-family: var(--font-family-secondary);
      font-size: 14px;
      line-height: 20px;
    }
    ul {
      padding-left: 15px;
    }
  }
}
