.mobea-provider-actions-dialog {
  position: absolute;
  z-index: 1000;  

  .mobea__close-icon {
    position: absolute;
    right: 20px;
    top: 24px;
  }

  .mobea__provider-logo {
    max-height: 40px;
    max-width: 200px;

    &.mobea__blue-bike,
    &.mobea__delijn,
    &.mobea__li-bia-velo,
    &.mobea__mivb,
    &.mobea__de-fietsambassade{
      max-height: 48px;
    }
    
    &.mobea__donkey-republic{
      max-height: 44px;
    }
  }

  &__stripe {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 8px;
  }

  &__name {
    margin-top: 16px;
    color: var(--color-gray-500);
    font-size: 22px;
    font-weight: 500;
    text-align: center;
  }

  &__info {
    margin-top: 24px;
    color: var(--color-gray-500);
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }

  .mobea__button {
    margin-top: 12px;    
  }
}
