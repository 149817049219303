.no-providers-available {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 120px;

  &__text {
    margin-top: 8px;
    color: var(--color-gray-300);
    font-family: var(--font-family-secondary);
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    max-width: 260px;
  }
}
