.mobea-passenger-details {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin: 32px -24px -24px;
  background-color: var(--color-background);

  &__form {
    background-color: white;
    padding: 0 32px 32px;

    &__name {
      width: calc(50% - 16px);
      margin-right: 32px;
      float: none;
      display: inline-block;
    }

    &__surname {
      width: calc(50% - 16px);
      float: none;
      display: inline-block;
    }

    &__dob {
      margin-top: 12px;
      width: 100%;
      float: none;
    }
  }

  &__empty-space {
    flex: 1 1 auto;
    min-height: 50px;
  }

  &__summary {
    padding: 32px 32px 24px;

    @media screen and (max-width: 360px) {
      padding: 24px 16px 24px;
    }

    &__price-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      font-size: 15px;

      .price-label {
        color: var(--color-gray-400);
        font-family: var(--font-family-secondary);
      }

      .price {
        color: var(--color-gray-400);
        font-family: var(--font-family-numbers);
        font-weight: bold;
      }
    }
  }
}
