.mobea__bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 50;
  width: 100%;
  padding-top: 18px;
  transition: opacity 0.5s;
  opacity: 1;
  padding-bottom: cal(env(safe-area-inset-bottom, 0px));
  height: calc(58px + env(safe-area-inset-bottom, 0px));

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: relative;
    z-index: 5;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: calc(-500px + 50%);
    width: 1000px;
    height: 100%;
    border-bottom-color: var(--color-background);
    border-bottom-width: calc(env(safe-area-inset-bottom, 0px));
    border-bottom-style: solid;

    > svg {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
    }
  }

  &__simple-item {
    margin-top: -5px;
    width: 36px;
    display: block;

    a {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: var(--color-gray-200);
      text-decoration: none;

      svg {
        .mobea__navigation__active {
          fill-opacity: 0;
        }
        .mobea__navigation__inactive {
          fill-opacity: 1;
        }
      }

      &.active {
        color: var(--color-primary);

        svg {
          .mobea__navigation__active {
            fill-opacity: 1;
          }
          .mobea__navigation__inactive {
            fill-opacity: 0;
          }
        }
      }

      &__main {
        width: 68px;
        height: 36px;
        position: relative;
      }

      &.mobea__disabled {
        pointer-events: none;
      }
    }

    &__label {
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
      text-align: center;
    }
  }

  &__main-item {
    width: 68px;
    height: 66px;
    display: block;
    position: absolute;
    top: -34px;
    margin: 0 auto;
    left: 0;
    right: 0;

    > a {
      text-decoration: none;
      display: block;
      width: 60px;
      height: 60px;
      background-color: var(--color-primary);
      top: 0;
      left: 4px;
      border-radius: 50%;
      position: absolute;
      overflow: hidden;
      text-indent: 100px;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);

      &:before,
      &:after {
        content: " ";
        position: absolute;
        background-color: var(--color-text-inverted);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }

      &:before {
        width: 3px;
        height: 24px;
      }

      &:after {
        width: 24px;
        height: 3px;
      }

      &.mobea__disabled {
        background-color: var(--color-background);

        &:before,
        &:after {
          background-color: white;
        }
      }
    }
  }
}
