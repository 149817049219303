.mobea__verts-package {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 176px;
  height: 180px;
  padding: 32px 12px 12px;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  margin-top: 8px;
  margin-right: 8px;

  .selected-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: 2px solid var(--color-gray-100);
    transition: all 0.2s;

    &.selected {
      background-color: var(--color-primary);
      border: none;
    }
  }

  .car-image {
    width: 72px;
    margin-bottom: 16px;
  }

  .price-range {
    font-family: var(--font-family-numbers);
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 32px;
  }

  .people-info {
    color: var(--color-gray-300);
    font-family: var(--font-family-numbers);
    font-size: 12px;
    margin-bottom: 8px;
    line-height: 20px;
    height: 20px;
    width: 100%;
  }

  .package-name {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 8px;
  }
}
