.mobea__banner {
  height: 80px;
  width: 250px;
  padding: 8px 20px;
  position: relative;
  margin: 0 auto;

  &__text {
    height: 64px;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    text-align: center;
    margin: 0;
  }

  &__red-corner {
    position: absolute;
    top: 0;
    left: 0;
    height: 26px;
    width: 26px;
    border-top: 8px solid var(--color-primary);
    border-left: 8px solid var(--color-primary);
  }
  &__blue-corner {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 26px;
    width: 26px;
    border-bottom: 8px solid var(--color-secondary);
    border-right: 8px solid var(--color-secondary);
  }
}
