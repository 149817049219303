.route-planner {
  padding: 0 24px;
  margin-bottom: -16px;

  &__scroll-container {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
  }

  &__search-button {
    margin-top: 24px;
  }

  .route-trips {
    position: relative;
    margin-top: 16px;

    .mobea-option-loader {
      padding-top: 24px;

      .name-info-placeholders {
        padding-bottom: 24px;
      }
    }

    .route-planner-trip-option {
      border-bottom: 1px solid var(--color-background);
    }

    .no-providers-available {
      margin-bottom: 12px;
    }
  }
}

.route-planner-location-picker {
  .mobea-location-picker__location {
    top: 16px;
  }
}
