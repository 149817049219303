@import "styles/constants.scss";

.mobea__settings {
  @include fullscreen-page;
  background-color: var(--color-background);
  padding: 32px 0 0;

  &__header {
    margin-bottom: 8px;

    > h1 {
      font-size: 22px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      margin: 0;
    }
  }

  &__content {
    margin-bottom: 70px;
  }

  &__section {
    &__headline {
      height: 60px;
      margin: 0;
      padding: 24px 32px 16px 32px;
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      opacity: 0.72;
      text-transform: uppercase;
    }

    &__items {
      width: 100%;
      background-color: white;
    }

    &__item {
      height: 72px;
      padding: 16px 16px 16px 32px;
      width: 100%;
      display: flex;
      flex-direction: row;
      position: relative;

      &__icon {
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
        border-radius: 50%;
        margin-right: 16px;
        position: relative;
        overflow: hidden;

        &__bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: var(--color-primary);
          opacity: 0.2;
        }

        > svg {
          width: 20px;
          height: 20px;
          display: block;
          margin: 10px;
          color: var(--color-primary);
        }
      }

      &__text {
        flex: 1 1 auto;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        padding: 12px 0 8px 0;
        height: 100%;
        margin: 0;
      }

      &__action {
        flex: 0 0 auto;
      }

      &.log-out {
        color: var(--color-primary);
        background-color: var(--color-background);
      }

      &.log-out &__text {
        font-weight: bold;
      }

      &.log-out &__icon__bg {
        background: none;
      }

      & button.mobea__settings__link {
        border: 0;
        outline: 0;
        background: none;
      }
    }
  }

  &__language-picker {
    text-transform: uppercase;
    text-align: center;
    color: var(--color-primary);
    font-family: var(--font-family-secondary);
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;

    &.mobea__dropdown {
      margin-right: 18px;
    }

    .mobea__dropdown {
      height: 40px;

      &__select {
        &__value {
          font-size: 16px;
          line-height: 20px;
          display: inline-block;
        }
      }

      &__options {
        border: 0 none;
        margin-top: 8px !important;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
        width: 72px;
        left: -26px;
      }

      &__option {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        font-weight: 400;
        color: var(--color-gray-500);
        text-align: center;

        &__current {
          font-weight: 500;
          color: var(--color-primary);
        }
      }
    }
  }

  &__link {
    display: block;
    color: var(--color-gray-200);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    > svg {
      display: block;
      transform: rotate(90deg);
      position: absolute;
      margin: auto;
      top: 0;
      right: 38px;
      bottom: 0;
      pointer-events: none;
    }
  }
}

.mobea__contact-us {
  &__call-us {
    color: transparent;
    text-decoration: none;
    width: 100%;
    height: 100%;
    padding-top: 16px;
    padding-bottom: 12px;
    display: block;
    position: relative;

    &__text {
      color: var(--color-text-inverted);
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      padding-top: 16px;
      padding-bottom: 12px;
      display: block;
    }
  }
}
