.mobea-dashboard__expired {
  margin: 48px 16px 62px;

  @media screen and (max-width: 360px) {
    margin-top: 38px;
  }

  &__text {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: var(--color-gray-300);
  }

  &__image {
    margin-top: 32px;
    text-align: center;
  }

  &__add-expense {
    margin-top: 50px;

    @media screen and (max-width: 360px) {
      margin-top: 10px;
    }
  }

  &__icon {
    text-align: center;
    margin-top: 16px;
    > svg {
      width: 64px;
      height: 60px;
    }
  }
}
