@font-face {
    font-family: "Lato";
    src: url("/static/fonts/Lato-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Lato";
    src: url("/static/fonts/Lato-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Lato";
    src: url("/static/fonts/Lato-Bold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }