.mobea-order-delijn {
  &__logo {
    height: 170px;
    text-align: center;
    padding: 40px;

    > svg {
      height: 90px;
    }
  }

  &__ticket {
    flex: 1 1 auto;
    background-color: var(--color-background);
    margin-left: -24px;
    margin-right: -24px;
    padding: 0 32px;
    margin-bottom: -32px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;

    &__details {
      flex: 1 1 auto;
      font-family: "FlandersArtSans";
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      &__name {
        height: 67px;
        color: #999999;
        font-size: 56px;
        line-height: 67px;
        margin: 0;
        font-weight: 400;
        margin-bottom: 4px;
      }

      &__info {
        font-size: 15px;
        text-align: center;
        color: #666666;
        letter-spacing: 0.6px;
        line-height: 25px;
        margin: 0;
      }
    }
  }

  .mobea__modal__dialog__image > svg {
    color: var(--color-primary);
  }
}
