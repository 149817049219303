.mobea__date-of-birth-picker-modal {
  .mobea__modal__dialog {
    height: auto;
  }
}

.mobea__datepicker__date-of-birth {
  select {
    color: var(--color-gray-500);
    border: 0 none;
    background: white;
    outline: 0;
    -webkit-appearance: none;
    border-bottom: 1px solid var(--color-gray-100);
    border-radius: 0;
    font-size: 16px;
    line-height: 24px;
    font-family: var(--font-family-secondary);
    min-width: 60px;
    box-sizing: border-box;
    position: relative;
    padding-right: 20px;
    appearance: auto;
    appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 386.3 386.3"><path d="M0 96.9l193.1 192.5L386.3 96.9z" fill="grey"/></svg>');
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: right;
  }
  &__month {
    margin-right: 20px;
  }
}
