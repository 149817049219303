.verts-order-map {
  .map-actions {
    bottom: 132px;
  }

  .map-container {
    top: 0;
  }

  .mobea-bottom-slider {

    .title-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      .mobea-bottom-slider__title {
        margin-left: 10px;
      }
    }

    &__content {
      width: 100%;
      padding-right: 0;
      padding-left: 0;
    }

  }
}
