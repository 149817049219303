.mobea-mobit-unlock {
  /* base class for nested page content */
  &__content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }

  &__code {
    padding: 0 8px;

    @media screen and (max-width: 360px) {
      padding: 0;
    }

    &__text {
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      margin: 24px 0;
    }

    &__input {
      width: auto !important;
      margin-bottom: 34px;

      .react-code-input {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 313px;
        margin: 0 auto;

        input {
          margin: 0;
          border: 1px solid var(--color-gray-100) !important;
          border-radius: 2px !important;
          background-color: #fff;
          padding: 0 !important;

          &:focus {
            border-color: var(--color-primary) !important;
          }
        }
      }
    }
  }

  &__image {
    flex: 1 1 auto;
    background-color: var(--color-background);
    margin-left: -24px;
    margin-right: -24px;
    padding: 0 32px;
    margin-bottom: -32px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;

    > img {
      display: inline-block;
      width: 240px;
      height: auto;
      margin: 32px auto 0;

      &.hidden {
        display: none;

        /* do not hide on tablets */
        @media screen and (min-height: 1000px) {
          display: block;
        }
      }
    }

    &__empty-space {
      flex: 1 1 auto;
      min-height: 16px;
    }

    &__unlock {
      margin-top: 0;
    }
  }

  #hidden-video-element {
    display: none;
  }

  .mobea-mobit-scan__preview__controls {
    margin-top: 24px;
    position: static;
  }
}
