.mobea__velo-ticket {
  background-color: var(--color-ticket-background);
  padding-bottom: 0;
  position: relative;

  .mobea__travel-pass__header__logo {
    img {
      width: 48px;
    }
  }

  .status-section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 32px;

    &__status {
      position: absolute;
      left: 0;
      padding: 8px 16px;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      border-radius: 2px;
      color: var(--color-status-grey);

      .background {
        opacity: 0.1;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: var(--color-status-grey);
      }

      &.active {
        color: var(--color-status-blue);

        .background {
          background-color: var(--color-status-blue);
        }
      }

      &.expired,
      &.charged {
        color: var(--color-status-green);

        .background {
          background-color: var(--color-status-green);
        }
      }

      @media screen and (max-width: 360px) {
        max-width: 140px;
      }
    }

    &__day-pass {
      max-width: 150px;
      color: var(--color-velo);
      font-size: 28px;
      font-weight: 500;
      line-height: 36px;
      transition: opacity 0.2s;
      text-align: right;
    }
  }

  .image-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    transition: opacity 0.2s;

    &__bike-image {
      height: 112px;
    }
  }

  .info-section {
    margin-top: 32px;
    background-color: white;
    margin-left: -16px;
    margin-right: -16px;
    padding: 16px 16px 0;
    overflow: hidden;
    transition: max-height 0.2s;
    max-height: 500px;
    
  }

  .preview-info-section {
    margin-left: -16px;
    margin-right: -16px;
    padding: 16px;
    background-color: var(--color-ticket-background);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    .mobea__ticket-start-end {
      opacity: 0.5;
    }
  }

  .details-section {
    margin-top: 32px;
    background-color: white;
    margin-left: -16px;
    margin-right: -16px;
    padding: 16px 16px 0;

    @media screen and (max-width: 360px) {
      margin-left: -12px;
      margin-right: -12px;
    }

    .mobea__ticket-start-end {
      margin-bottom: 16px;
    }

    &__separator {
      position: relative;
      height: 1px;
      background-color: var(--color-background);
    }

    &__info {
      margin-top: 12px;
      padding-bottom: 12px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      overflow: hidden;
      max-height: 100px;
      transition: max-height 0.2s;

      .mobea__label-value {
        &__value {
          color: var(--color-gray-500);
        }

        &.charged {
          .mobea__label-value__value {
            color: var(--color-error);
          }
        }
      }
    }

    &__charge-info {
      color: var(--color-error);
      font-size: 12px;
      line-height: 20px;
      padding-bottom: 48px;
    }
  }

  .actions-section {
    background-color: white;
    margin-left: -16px;
    margin-right: -16px;
    border-radius: 0 0 16px 16px;
    display: flex;
    align-items: center;
    padding: 16px;
    overflow: hidden;
    transition: max-height 0.2s;
    max-height: 80px;

    @media screen and (max-width: 360px) {
      margin-left: -12px;
      margin-right: -12px;
    }

    .phone-button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 48px;
      padding: 0;
      margin-left: 12px;

      svg {
        margin-top: 4px;
      }

      &.wide {
        width: 100%;
        margin: 0;

        .phone-icon {
          margin-bottom: -6px;
          margin-right: 12px;
        }
      }
    }
  }

  .contact-velo-button {
    width: 100%;
  }

  &.preview {
    background-color: white;

    .status-section {
      &__day-pass {
        opacity: 0.5;
      }
    }

    .info-section {
      max-height: 0;
    }

    .image-section {
      opacity: 0.5;
    }

    .details-section {
      background-color: var(--color-ticket-background);
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;

      .mobea__ticket-start-end {
        opacity: 0.5;
      }

      &__separator {
        max-height: 0;
      }

      &__info {
        padding: 0;
        max-height: 0;
      }
    }

    .actions-section {
      max-height: 0;
    }
  }
}
