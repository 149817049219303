.mobea-ticket-mobit {
  .mobea__travel-pass {
    max-width: 500px;
    margin: 0 auto;
  }

  .mobea__modal__dialog__image {
    color: var(--color-primary);
  }
}
