.mobea__tutorial-dialog {
  overflow: hidden;
  
  .mobea__modal__dialog {
    padding-bottom: 24px;   

    &__content {
      overflow: hidden;
    }
  }

  &__steps {
    overflow: hidden;
    position: relative;
    transition: margin-left 0.3s;
  }

  &__step {
    float: left;
  }

  &__image {
    height: 80px;
    color: var(--color-primary);

    img,
    svg {
      height: inherit;
    }
  }

  &__title {
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    margin: 0;
  }

  .mobea__steps {
    margin: 24px 0;
    justify-content: center;
  }

  &__info {
    margin-top: 16px;

    &__info-line {
      display: flex;
      align-items: flex-start;

      &__bullet {
        font-family: var(--font-family-secondary);
        margin-right: 20px;
        margin-left: 12px;
        font-size: 14px;
      }

      &__text {
        font-family: var(--font-family-secondary);
        line-height: 24px;
        font-size: 14px;
        text-align: left;
      }

      &:not(:first-of-type) {
        margin-top: 4px;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-top: 2px;

    &__skip {
      color: var(--color-gray-200);
    }

    .mobea__button {
      width: auto;
      padding-bottom: 0;
      outline: 0 none !important;
      min-width: 64px;
      min-height: 24px;

      &:first-of-type {
        padding-left: 0;
        text-align: left;
      }
      &:last-of-type {
        padding-right: 0;
        text-align: right;
      }
    }
  }
}
