.mobea__overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.48);
  visibility: hidden;
  opacity: 0;
  transition: opacity 100ms ease-in;

  &.mobea__expanded {
    opacity: 1;
    visibility: visible;
  }
}

.mobea__bold {
  font-weight: 600;
}

.mobea__medium {
  font-weight: 500;
}

.mobea__arial {
  font-family: var(--font-family-numbers);
}

.mobea__hidden {
  visibility: hidden;
}

.mobea__no-display {
  display: none;
}

.mobea__close-button {
  position: relative;
  width: 30px;
  height: 30px;
  border: 0 none;
  background-color: transparent;
  color: var(--color-primary);
  padding: 0;

  &:before,
  &:after {
    content: " ";
    position: absolute;
    width: 60%;
    top: 45%;
    left: 20%;
    height: 10%;
    transform: rotate(45deg);
    transform-origin: center;
    background-color: currentColor;
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.mobea__sharpen-image {
  image-rendering: pixelated;
}

.mobea__no-wrap {
  white-space: nowrap;
}

/* Day Picker customization */
.DayPicker {
  .DayPicker {
    &-wrapper {
      outline: 0 none;
    }

    &-NavBar {
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      height: 20px;

      > .DayPicker-NavButton {
        top: 0;
        margin-top: 0;

        &--prev {
          left: 0;
        }

        &--next {
          right: 0;
        }
      }
    }

    &-Month {
      margin: 0;
    }

    &-Caption {
      font-family: var(--font-family-numbers);
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
      margin-bottom: 16px;

      > div {
        font-size: inherit;
        font-weight: inherit;
      }
    }

    &-Weekday {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: var(--color-gray-200);
    }

    &-Week {
      height: 40px;
    }

    &-Day {
      padding: 0;
      font-family: var(--font-family-numbers);
      font-size: 14px;
      line-height: 40px;
      outline: 0 none;
      width: 40px;
    }
  }

  .DayPicker-Day--today {
    color: var(--color-primary);
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: var(--color-primary);
    color: #f0f8ff;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: var(--color-primary);
    color: #f0f8ff;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.mobea_field_label {
  font-size: 12px;
  line-height: 16px;
  color: var(--color-gray-300);
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
}

.mobea__contact-us {
  &.mobea__overlay {
    z-index: 1100;
  }
  .mobea__modal__dialog {
    z-index: 1101;
  }
}
