.mobea__order__ticket__footer {
  min-height: 90px;

  &__info {
    overflow: hidden;
    line-height: 24px;
    font-size: 15px;
    margin-bottom: 10px;

    &__amount {
      float: left;

      &.editor-view {
        float: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 32px;
      }

      &__editor {
        display: flex;
        align-items: center;

        &__amount-button {
          position: relative;
          flex-shrink: 0;
          font-size: 20px;
          width: 24px;
          height: 24px;
          border-radius: 12px;
          color: white;
          background: var(--color-primary);

          &.disabled {
            background-color: var(--color-gray-100);
          }

          &.minus {
            &:after {
              content: "";
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 10px;
              height: 2px;
              background: white;
            }
          }

          &.plus {
            &:before {
              content: "";
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 2px;
              height: 10px;
              background: white;
            }

            &:after {
              content: "";
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 10px;
              height: 2px;
              background: white;
            }
          }
        }

        input {
          width: 64px;
          height: 40px;
          text-align: center;
          border: 1px solid var(--color-gray-100);
          border-radius: 2px;
          margin-left: 12px;
          margin-right: 12px;
          font-family: var(--font-family-numbers);
        }
      }
    }

    &__price {
      float: right;

      &.full {
        float: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    &__label {
      margin-right: 5px;
      font-family: var(--font-family-secondary);
    }

    &__value {
      font-weight: 600;
      font-family: var(--font-family-numbers);
    }
  }

  &__button {
    position: relative;
  }

  &.wallets {
    .mobea__order__ticket__footer {
      &__info {
        &__value {
          float: right;
        }

        &__amount,
        &__price {
          float: none;
        }

        &__amount,
        &__wallet {
          margin-bottom: 0.25rem;

          &.editor-view {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}
