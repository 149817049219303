.mobea__no_balance {
  z-index: 1000;

  .mobea__modal__dialog__header__headline {
    font-family: var(--font-family-secondary);
  }
}

.mobea__out_of_budget_period {
  z-index: 1100;
}
