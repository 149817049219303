.mobea__verts-ticket {
  background-color: var(--color-ticket-background);
  padding-bottom: 0;
  position: relative;

  .status-section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 0;

    .mobea__spinner {
      position: relative;
    }

    &__status {
      margin-top: 24px;
      color: var(--color-gray-400);
      font-size: 16px;
      font-weight: 500;

      &.driver_found {
        color: var(--color-primary);
      }

      &.error {
        color: var(--color-red);
      }
    }

    &__info-text {
      color: var(--color-gray-300);
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      margin-top: 16px;
      padding: 0 16px;
      min-height: 72px;

      > span {
        animation: fadeIn 0.5s;
      }

      .action {
        color: var(--color-primary);
      }
    }
  }

  .date-time-price {
    justify-content: unset;

    &__section {
      width: 50%;
    }
  }

  .info-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
    padding-bottom: 16px;

    &__status-plate {
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__status {
        border-radius: 2px;
        padding: 8px 16px;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        max-width: 50%;
        position: relative;
        color: var(--color-status-grey);

        .background {
          opacity: 0.1;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: var(--color-status-grey);
        }

        &.arriving,
        &.waiting {
          color: var(--color-status-grey);
        }

        &.in_progress {
          color: var(--color-status-blue);

          .background {
            background-color: var(--color-status-blue);
          }
        }

        &.finished {
          color: var(--color-status-green);

          .background {
            background-color: var(--color-status-green);
          }
        }

        &.cancelled {
          color: var(--color-status-red);

          .background {
            background-color: var(--color-status-red);
          }
        }
      }
    }

    &__car-image {
      margin-top: 32px;
      width: 178px;
    }

    &__details-toggle {
      display: flex;
      align-items: center;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 24px;
      color: var(--color-primary);
      margin-top: 24px;

      &__icon {
        margin-left: 8px;
        margin-top: -2px;
      }
    }

    &__details {
      align-self: stretch;
      overflow: hidden;

      .mobea__label-value {
        &__label {
          line-height: 16px;
        }

        &__value {
          color: var(--color-gray-400);
          font-size: 14px;
        }

        &:not(:first-of-type) {
          margin-top: 20px;
        }
      }
    }
  }

  &__separator {
    position: absolute;
    left: 16px;
    right: 16px;
    height: 1px;
    background-color: var(--color-background);
  }

  .actions-section {
    background-color: white;
    margin-left: -16px;
    margin-right: -16px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    display: flex;
    align-items: center;
    padding: 0 16px 16px;

    @media screen and (max-width: 360px) {
      margin-left: -12px;
      margin-right: -12px;
    }

    .mobea__button {
      margin-top: 16px;
    }

    .phone-button {
      flex-shrink: 0;
      width: 48px;
      padding: 0;
      margin-left: 12px;

      svg {
        margin-top: 4px;
      }
    }

    &__fine-info {
      width: 100%;
      height: 80px;
      color: var(--color-error);
      font-size: 12px;
      line-height: 20px;
    }
  }

  &.preview {
    background-color: white;

    .info-section {
      &__status-plate {
        .car-plate {
          opacity: 0.5;
        }
      }

      &__car-image {
        opacity: 0.5;
      }
    }

    .route-section {
      background-color: #f5f6f7;
      opacity: 0.5;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }

  .contact-verts-button {
    width: 100%;
  }
}
