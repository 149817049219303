.tutorial {
  &__main {
    &_1,
    &_3,
    &_5,
    &_6 {
      transform: translateY(2px) scale(1.5);
    }

    &_2 {
      transform: scaleY(1.2);
      border-radius: 4px;
    }

    &_4 {
      border-radius: 100px;
      transform: translateY(-3px) scale(1.2);
    }
  }

  &__providers {
    &_1 {
      background-color: rgba(0, 0, 0, 0.24);
    }

    &_2 {
      border-radius: 100px;
    }

    &_4,
    &_5 {
      border-radius: 2px;
    }
  }
}
