.mobea-map-view-page {
  &__content {
    padding: 16px 24px;
  }

  .search-location-field-container {
    position: relative;
    border: 1px solid var(--color-gray-100);
    border-radius: 2px;
    background: white;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
    z-index: 2;

    .search-location-and-providers-field {
      .mobea__input-group {
        float: none;
        position: relative;
        border-bottom: none;
        padding: 4px 8px;
        height: auto;
      }     
    }
  }

  .mobea-providers-filter {
    position: relative;
    margin-top: 8px;
    margin-right: -24px;
    margin-left: -24px;
    padding-left: 24px;
  }

  .selected-provider-type {
    margin-top: 11px;
    position: relative;
    display: flex;
    align-items: center;
  }

  .mobea-bottom-slider {
     padding-bottom: 55px;
    .title-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      .mobea-bottom-slider__title {
        margin-left: 10px;
      }
    }

    .provider-title {
      display: flex;
      align-items: center;
      padding: 0 24px;

      &__icon {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__name {
        flex-grow: 1;
        text-align: left;
        margin-left: 8px;
      }

      .mobea__provider-logo {
        height: 24px;

        &.mobea__mivb {
          height: 28px;
        }
        &.mobea__blue-bike {
          height: 28px;
        }
        &.mobea__li-bia-velo{
          height: 30px;
        }
      }
    }

    &__content {
      width: 100%;
      padding-right: 0;
      padding-left: 0;
    }    
  }

  .bottom-nav-bar-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 50;
  }

  .search-location-details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 0 16px;
    margin-left: 24px;
    margin-right: 24px;
    border-bottom: 1px solid var(--color-background);

    &__address-distance {
      &__title {
        color: var(--color-gray-400);
        font-size: 20px;
        line-height: 24px;
      }

      &__subtitle {
        margin-top: 8px;
        color: var(--color-gray-300);
        font-family: var(--font-family-secondary);
        font-size: 14px;
        line-height: 20px;
      }

      &__distance {
        display: flex;
        align-items: center;
        margin-top: 16px;
        color: var(--color-gray-300);
        font-family: var(--font-family-numbers);
        font-size: 14px;
        line-height: 20px;

        svg {
          margin-right: 6px;
        }
      }
    }

    .route-directions-button {
      margin-top: 24px;
    }
  }

  .nearby-providers {
    position: relative;
    margin-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    min-height: 136px;

    .mobea__spinner {
      margin-top: 32px;
    }

    &__title {
      color: var(--color-gray-300);
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      margin-top: 8px;
      margin-bottom: 4px;
    }

    &__scroll-container {
      overflow-x: auto;
      margin-left: -24px;
      margin-right: -24px;
      padding-left: 24px;
      padding-right: 24px;

      .tiles-container {
        display: flex;
        align-items: center;
        padding-bottom: 8px;

        .tile-wrapper {
          padding: 4px 0;

          .mobea__provider-tile {
            flex-shrink: 0;
          }

          &:first-of-type {
            padding-left: 0px;
          }

          &:last-of-type {
            padding-right: 16px;
          }

          &:not(:first-of-type) {
            margin-left: 16px;
          }
        }
      }
    }

    .no-providers-available {
      border: 1px solid rgba(var(--color-gray-100), 0.5);
      border-radius: 2px;
      background-color: rgba(var(--color-background), 0.5);
      margin-top: 8px;
    }
  }  

  &.full { 
    .map-container {
      bottom: 0;     
    }
  }
}
