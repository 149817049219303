.mobea__de-lijn-ticket {
  &__name {
    height: 67px;
    color: #999999;
    font-size: 56px;
    line-height: 67px;
    margin: 0;
    font-weight: 400;
    padding-left: 24px;
    margin-bottom: 16px;
    font-family: "FlandersArtSans";
  }

  &__info {
    font-size: 15px;
    background-color: #f2f2f2;
    padding: 12px 29px 16px 22px;
    line-height: 28px;
    color: var(--color-gray-300);
    font-family: "FlandersArtSans";

    &__type {
      margin: 0;
      margin-bottom: 4px;
    }

    &__details {
      margin: 0;
      font-weight: 500;
    }

    &__price {
      margin-top: 22px;
      margin-bottom: 0;
    }
  }

  &__full-details {
    transition: max-height 0.5s, opacity 0.5s;
    max-height: 300px;
    overflow: hidden;

    &.hidden {
      max-height: 0;
      opacity: 0;
    }
  }

  &__verification {
    margin-top: 20px;
    overflow: auto;
    font-family: "FlandersArtSans";

    &__stripe {
      height: 46px;
      background-color: #666666;
      color: white;
      font-size: 20px;
      line-height: 30px;
      padding: 9px 22px 12px;
      text-transform: uppercase;
      font-family: "FlandersArtSans";
      font-weight: 600;
      margin-bottom: 12px;
      animation: 2s infinite alternate blinking;
    }

    &__data {
      color: var(--color-gray-300);
      font-size: 15px;
      line-height: 22px;
      padding-left: 22px;
      float: left;
      overflow: auto;

      &:after {
        content: "";
        clear: both;
        display: table;
      }

      > p {
        margin: 0;
      }

      &__label {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &__bottom-logos {
    margin-top: 27px;
    height: 64px;
    position: relative;

    &__vlaander {
      float: left;
      height: 45px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: auto;
    }

    &__delijn {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 100%;
      width: auto;
    }
  }

  &.mobea__ticket-preview {
    .mobea__de-lijn-ticket__name,
    .mobea__de-lijn-ticket__info {
      opacity: 0.48;
    }
  }
}

@keyframes blinking {
  from {
    background-color: #666666;
  }
  to {
    background-color: var(--color-delijn);
  }
}
