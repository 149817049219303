.mobea__checkbox-group {
  line-height: 24px;
  position: relative;
  clear: both;
  text-align: left;

  input {
    visibility: hidden;
    width: 0;
    height: 0;
    float: left;

    + label > svg {
      color: transparent;
      width: 24px;
      height: 24px;
      border-radius: 2px;
      visibility: visible;
      display: inline-block;
      top: 50%;
      position: absolute;
      left: 0;
      border: 1px solid var(--color-gray-100);
      box-sizing: border-box;
      transform: translateY(-50%);
      pointer-events: none;
    }

    &:checked + label > svg {
      color: var(--color-text-inverted);
      background-color: var(--color-primary);
      border: 0 none;
    }

    &:disabled + label > svg {
      color: white;
      background-color: var(--color-gray-100);
    }
  }

  label {
    font-size: 16px;
    padding-left: 40px;
    position: relative;
    display: inline-block;
    height: 100%;
  }

  &.shape-circle {
    input {
      + label {
        font-family: var(--font-family-secondary);

        > svg {
          border-radius: 50%;
          border-width: 2px;
        }
      }
    }
  }
}
