.mobea__source-destination {
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  position: relative;

  svg {
    color: var(--color-primary);
  }

  &__route-icon {
    position: absolute;
    top: 26px;
    left: 0;

    @media screen and (max-width: 360px) {
      left: -8px;
    }
  }

  &__fields {
    flex: 1 0;
    margin-left: 32px;
    margin-right: 16px;

    .mobea__input-group {
      width: 100%;

      &:not(:first-of-type) {
        margin-top: 8px;
      }
    }

    @media screen and (max-width: 360px) {
      margin-left: 12px;
      margin-right: 8px;
    }
  }
}
