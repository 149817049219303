.trip-route-start-end-marker {
  margin-left: -6px;
  margin-top: -6px;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  border: 2px solid white;
  background: var(--color-primary);
}

.trip-route-stop-marker {
  margin-left: -3px;
  margin-top: -3px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  border: 1px solid black;
  background: white;
}
