.mobea-confirm-iban {
  .m_page__header {
    padding: 0 24px;
  }

  &__content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    background-color: var(--color-background);
    padding-bottom: 32px;
  }

  &__form {
    background-color: white;
    padding: 0 32px 32px 32px;

    &__text {
      color: var(--color-gray-300);
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      max-width: 320px;
      margin: 0 auto;
      margin-bottom: 16px;
    }

    &__iban {
      width: 100%;

      > input {
        letter-spacing: 3px;
        caret-color: var(--color-gray-500);
        position: absolute;
        z-index: 1;
        background-color: transparent;
        left: 0;
        bottom: 0;
      }

      &.mobea__iban-invalid input {
        border-bottom: 2px solid var(--color-error);
      }

      &__value {
        position: absolute;
        bottom: 0px;
        left: 0;
        padding-top: 4px;
        line-height: 24px;
        font-size: 16px;
        font-family: var(--font-family-numbers);
        color: var(--color-gray-500);
        letter-spacing: 3px;
        height: 34px;
      }

      &__placeholder {
        color: var(--color-gray-200);
      }

      &__error {
        color: var(--color-error);
        font-family: var(--font-family-secondary);
        font-size: 12px;
        letter-spacing: 0;
        line-height: 24px;
        position: absolute;
        left: 0;
        bottom: -24px;
      }
    }
  }

  &__empty-space {
    flex: 1 1 auto;
    min-height: 32px;
  }

  &__button {
    padding: 0 32px;
  }
}
