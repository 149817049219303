.mobea-ticket-delijn {
  .mobea__travel-pass {
    max-width: 500px;
    margin: 0 auto;
  }

  &__info {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0 2px 4px 2px;

    > svg {
      height: 100%;
      width: auto;
    }
  }
}
