.route-planner-trip-segment-stops {
  &__stop {
    position: relative;
    padding-top: 4px;
    padding-bottom: 4px;

    .name-time {
      display: flex;
      align-items: flex-start;
      font-size: 12px;
      line-height: 16px;

      &__name {
        flex: 1 0;
        font-family: var(--font-family-secondary);
      }

      &__time {
        flex-shrink: 0;
        font-family: var(--font-family-numbers);
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 8px;
      left: -18px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      border: 2px solid var(--color-primary);
      background: white;
    }

    &.first,
    &.last {
      padding-left: 18px;

      .name-time {
        &__name {
          font-weight: bold;
        }
      }

      &:before {
        content: "";
        position: absolute;
        left: 4px;
        top: 0;
        bottom: 0;
        border-left: 2px solid var(--color-primary);
      }
    }

    &.first {
      padding-top: 0;

      &:before {
        top: 10px;
      }

      &:after {
        left: 0;
        top: 2px;
        background: white;
      }
    }

    &.last {
      padding-bottom: 0;

      &:before {
        bottom: calc(100% - 10px);
      }

      &:after {
        left: 0;
        top: 6px;
        background: var(--color-primary);
      }
    }
  }

  &__other-stops {
    padding-left: 18px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 4px;
      top: 0;
      bottom: 0;
      border-left: 2px dashed var(--color-primary);
    }

    &.open {
      &:before {
        border-left-style: solid;
      }
    }
  }

  &__show-hide {
    display: flex;
    align-items: center;
    color: var(--color-primary);
    font-family: var(--font-family-secondary);
    font-size: 12px;
    line-height: 16px;
    min-height: 32px;

    svg {
      margin-left: 4px;
    }
  }
}
