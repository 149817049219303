.mobea__offline-banner.active ~ div > .verts-map {
  top: 32px;
}

.verts-map {
  .m_page__header {
    position: relative;
    z-index: 20;
    padding: 10px 24px 10px 24px;
    margin: 0;
    height: auto;

    h1 {
      display: flex;
      align-items: center;
      position: relative;
    }

    &__title {
      flex-grow: 1;
    }

    .subtitle {
      color: var(--color-primary);
      font-size: 12px;
      font-weight: normal;
      line-height: 20px;
      text-align: center;
    }
  }

  

  .mobea-bottom-slider {

    .title-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      .mobea-bottom-slider__title {
        margin-left: 10px;
      }
    }

    &__content {
      width: 100%;
      padding-right: 0;
      padding-left: 0;
    }
  }

  .bottom-actions {
    padding: 24px 32px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .mobea__taxi-pickup-icon {
    .container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--color-primary);
      border-radius: 4px;
      background-color: white;
      padding: 12px;
      max-width: 110px;
      transform: translate(-50%, calc(-100% - 6px));

      .address {
        color: var(--color-gray-400);
        font-family: var(--font-family-numbers);
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        margin-top: 6px;
      }

      &:after {
        content: "";
        position: absolute;
        top: calc(100% - 4px);
        left: calc(50% - 5px);
        height: 10px;
        width: 10px;
        transform: rotate(-45deg);
        border-radius: 0 0 2px 0;
        background-color: white;
        border: 1px solid var(--color-primary);
        border-top: none;
        border-right: none;
      }
    }
  }

  .mobea__taxi-waiting-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 40px;
    border-radius: 4px;
    background-color: var(--color-primary);
    margin-top: -36px;
    margin-left: -20px;

    &:after {
      content: "";
      position: absolute;
      top: calc(100% - 4px);
      left: calc(50% - 4px);
      height: 8px;
      width: 8px;
      transform: rotate(-45deg);
      border-radius: 0 0 2px 0;
      background-color: var(--color-primary);
    }
  }

  .mobea__location-cursor-icon {
    margin-top: -40px;
    margin-left: -20px;
  }

  .driver-info {
    padding: 24px 24px 12px;

    .mobea__label-value__value {
      color: var(--color-gray-400);
      font-size: 14px;
      line-height: 20px;
    }

    .car-plate-section {
      display: flex;
      align-items: flex-end;
      justify-content: end;
      margin-top: 24px;
      flex-wrap: wrap;
      row-gap: 10px;

      &__car{
        flex: 1 1 auto;
      }
    }
  }

  .route-info {
    position: relative;
    padding: 0 32px;

    &__separator {
      position: absolute;
      left: 16px;
      right: 16px;
      height: 1px;
      background-color: var(--color-background);
    }
  }
}
