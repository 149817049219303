.mobea__mobit-ticket {
  transition: all 0.5s;
  background-color: var(--color-background);
  overflow: hidden;

  .mobea__travel-pass__header {
    margin-bottom: 28px;

    @media screen and (max-height: 620px) {
      margin-bottom: 16px;
    }

    &__logo {
      width: 53px;

      > svg {
        width: 100%;
      }
    }
  }

  &__name {
    color: #fccd00;
    font-size: 28px;
    line-height: 36px;
    font-weight: 500;
    text-align: center;
    margin: 0;

    &__wrapper {
      display: flex;
      justify-content: space-between;
      height: 36px;
      margin-bottom: 32px;
    }

    &__green {
      color: var(--color-mobit);
    }

    &__status {
      height: 28px;
      margin: 4px 0;
      padding: 7px 16px;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 17px;
      position: relative;
      color: var(--color-status-grey);

      .background {
        opacity: 0.1;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: var(--color-status-grey);
      }

      &.started,
      &.paused {
        color: var(--color-status-blue);

        .background {
          background-color: var(--color-status-blue);
        }
      }

      &.ended {
        color: var(--color-status-green);

        .background {
          background-color: var(--color-status-green);
        }
      }
    }
  }
  &__image {
    width: 160px;
    min-height: 104px;

    @media screen and (max-height: 620px) {
      width: 120px;
      min-height: 78px;
    }

    &__wrapper {
      position: relative;
      width: 100%;
      text-align: center;
      margin-bottom: 32px;

      @media screen and (max-height: 620px) {
        margin-bottom: 16px;
      }
    }
  }

  &__bottom-section {
    min-height: 32px;
    background-color: #fff;
    margin: 0 -16px -16px;
    padding: 16px;
  }

  &__info {
    &__hr {
      width: 100%;
      height: 1px;
      background-color: var(--color-background);
      margin: 16px 0;
      border: 0 none;
    }

    &__item {
      &__price {
        .mobea__mobit-ticket__info__item__label {
          > svg {
            height: 16px;
            width: 16px;
            vertical-align: sub;
          }
        }
        .mobea__mobit-ticket__info__item__value {
          font-family: var(--font-family-numbers);
          font-size: 14px;
        }

        &.fined {
          color: var(--color-error);
        }
      }
    }
  }

  &__footer {
    min-height: 52px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &__button {
      margin: 0;
    }

    &__fine-info {
      color: var(--color-error);
      font-size: 12px;
      line-height: 20px;
      margin: 4px 0;
    }

    &__no-fine-info {
      height: 24px;
    }
  }

  &.preview {
    background-color: #fff;

    .mobea__mobit-ticket {
      &__name {
        opacity: 0.5;

        &__wrapper {
          transition: all 0.5s;
          margin-bottom: 20px;
        }
      }
      &__image {
        transition: all 0.5s;
        width: 120px;
        min-height: 80px;

        &__wrapper {
          transition: all 0.5s;
          margin-bottom: 16px;
          opacity: 0.5;
        }
      }

      &__bottom-section {
        transition: all 0.5s;
        background-color: var(--color-background);
        padding-top: 16px;
        padding-bottom: 12px;
        opacity: 0.5;
      }

      &__info {
        transition: all 0.5s;

        &__item.hide-in-preview {
          transition: all 0.5s;
          height: 0;
          overflow: hidden;
        }

        &__hr {
          transition: all 0.5s;
          margin: 0;
        }
      }

      &__footer {
        transition: all 0.5s;
        height: 0;
        margin: 0;
        padding: 0;
        min-height: 0;
      }
    }
  }
}
