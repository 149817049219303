.mobea__ticket-start-end {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .mobea__label-value {
    &__label {
      font-size: 14px;
      color: var(--color-gray-500);
    }

    &__value {
      color: var(--color-gray-500);
    }
  }

  &__separator {
    position: relative;
    flex: 1 0;

    > svg {
      color: var(--color-primary);
      transform: rotate(180deg);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
}
