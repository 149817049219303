.font-daxlinepro {
  .mobea__button:not(.mobea__action-button) {
    padding-top: 12px;
    padding-bottom: 8px;
  }
}

.font-lato {
  .m_page__header {
    > h1 {
      line-height: 24px;
    }
  }

  .mobea__settings__section__item__text {
    padding: 10px 0;
  }

  .mobea__tab-pane-nav__tab {
    font-size: 13px;
    line-height: 20px;
  }

  .mobea-history__no-costs__start {
    font-size: 17px;
  }

  .mobea__provider-tile__text__name {
    position: relative;
    top: -2px;
    letter-spacing: -0.6px;
  }

  .mobea__travel-pass__header__provider {
    padding-top: 0;
  }

  .DayPicker {
    .DayPicker-Day {
      font-size: 13px;
    }
  }

  .mobea-order-nmbs__choose-ticket {
    .check-name-info__name {
      margin-top: -2px;
    }
  }

  .mobea-find-bike__controls {
    &__location-ring__ring,
    &__code-report__report {
      > span {
        margin-top: -2px;
      }
    }
  }
}
