.mobea__modal {
  animation: fadeIn 0.2s;
  position: relative;
  z-index: 1000;

  &__dialog {
    width: calc(100% - 32px);
    position: absolute;
    max-width: 500px;
    max-height: calc(100% - 32px);
    margin: 16px auto;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 32px;
    overflow-y: scroll;
    background-color: white;
    animation: popUp 0.2s;
    transition: transform 0.2s;
    z-index: 1001;

    @media screen and (max-height: 600px) {
      padding: 24px;
    }

    &__header {
      padding-bottom: 24px;
      position: relative;

      &__headline {
        color: var(--color-primary);
        font-size: 22px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        margin: 0;
      }

      &__close {
        position: absolute !important;
        top: 0px;
        right: -12px;
      }

      .mobea__close-button {
        width: 32px;
        height: 24px;
      }
    }

    &__closeable {
      .mobea__modal__dialog__header__headline {
        margin: 0 20px;
      }
    }

    &.mobea__error {
      .mobea__modal__dialog__header__headline {
        color: var(--color-error);
      }
    }

    &.mobea__secondary {
      .mobea__modal__dialog__header {
        &__headline,
        &__close {
          color: var(--color-secondary);
        }
      }
    }

    &__image {
      width: 88px;
      height: 88px;
      margin: 0 auto 24px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__content {
      color: var(--color-gray-500);
      font-size: 14px;
      line-height: 24px;
      text-align: center;

      > p:only-child {
        margin: 4px 0;
      }
    }

    &__footer {
      > .mobea__button {
        margin-top: 24px;
      }
        > .mobea__button:not(:first-of-type) {
          margin-top: 16px;
        }
      
    }
  }
}

@keyframes popUp {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
