@import "styles/constants";

.mobea-profile {
  padding: 32px;

  &__form {
    .mobea__label-value {
      margin-bottom: 16px;

      &__label {
        line-height: 20px;
        font-size: 13px;
      }

      &__value {
        color: var(--color-gray-500);
        font-size: 16px;
      }
    }

    .mobea__input-group {
      width: 100%;
      margin-bottom: 24px;
    }
  }

  &__empty-space {
    flex: 1 1 auto;
    min-height: 32px;
  }
}
