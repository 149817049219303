.mobea-date-range-picker {
  display: flex;
  align-items: center;

  .mobea__range__end {
    margin-left: 8px;
  }
}

.mobea__datepicker-modal {
  .mobea__modal__dialog {
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 32px);
    overflow-x: hidden;

    &__header {
      flex: 0 0 auto;
    }

    &__content {
      flex: 1 1 auto;
      overflow-y: scroll;
    }

    &__footer {
      flex: 0 0 auto;
    }

    .mobea__range-picker {
      .DayPicker-Day {
        border-radius: 0 !important;

        &--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
          background-color: var(--color-primary-light) !important;
          color: var(--color-text-inverted);
        }

        &--start {
          border-top-left-radius: 50% !important;
          border-bottom-left-radius: 50% !important;
        }

        &--end {
          border-top-right-radius: 50% !important;
          border-bottom-right-radius: 50% !important;
        }
      }
    }
  }
}
