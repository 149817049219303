.verts-taxi {
  display: flex;
  align-items: center;

  &__color {
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 4px;
  }

  &__model {
    margin-left: 4px;
  }
}
