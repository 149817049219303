.mobea__close-icon {
  position: relative;

  &:before,
  &:after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    transform: rotate(45deg);
    transform-origin: center;
    background-color: currentColor;
  }

  &:after {
    transform: rotate(-45deg);
  }
}
