@import "styles/constants";

.mobea-tutorial {
  @include fullscreen-page;
  padding-top: 48px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  flex-flow: column;
  overflow-x: hidden;

  @media screen and (max-width: 340px) {
    padding-top: 32px;
  }

  .mobea__tutorial-dialog {
    position: relative;
    margin-bottom: 56px;

    &__step {
      padding: 0 32px;
    }

    &__image {
      height: 222px;
      text-align: center;

      @media screen and (max-width: 340px) {
        height: 180px;
      }
    }

    .mobea__steps {
      margin-top: 32px;
    }

    &__title {
      line-height: 34px;
      font-family: var(--font-family);
      font-weight: 600;
      font-size: 24px;

      @media screen and (max-width: 340px) {
        line-height: 28px;
        font-size: 20px;
      }
    }

    &__info {
      color: var(--color-gray-400);
      font-size: 16px;
      line-height: 24px;
      text-align: center;

      @media screen and (max-width: 340px) {
        font-size: 14px;
      }
    }

    &__actions {
      position: fixed;
      left: 32px;
      right: 32px;
      bottom: 0;
      margin: 0;
      height: 56px;
      background-color: white;

      .mobea__button {
        padding-top: 0;
        margin-top: 0;
      }
    }
  }
}
