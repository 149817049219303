.mobea-confirm-pin {
  &__no-code {
    margin-top: 24px;
    font-size: 14px;
    line-height: 24px;
    text-align: center;

    > a {
      text-decoration: none;
    }
  }

  &__code {
    display: block;
    position: relative;
    height: 38px;
    font-size: 20px;
    line-height: 34px;
    padding-bottom: 4px;
    margin-bottom: 24px;
    color: var(--color-primary);

    &__input {
      width: auto !important;
      margin-bottom: 34px;

      .react-code-input {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 360px;
        margin: 0 auto;

        input {
          width: 49px !important;
          margin: 0;
          border: 1px solid var(--color-gray-100) !important;
          border-radius: 2px !important;
          background-color: #fff;
          font-family: var(--font-family-numbers);
          font-size: 24px;
          line-height: 24px;

          &:focus {
            border: 2px solid var(--color-primary) !important;
          }
        }
      }
    }
  }

  .mobea__modal__dialog__image > svg {
    color: var(--color-primary);
  }
}
