.mobea__toggle-group {
  position: relative;

  &__checkbox {
    width: 68px;
    height: 32px;
    position: absolute;
    right: 0;
    top: 0;

    > input,
    > svg {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
    }

    > input {
      opacity: 0;
      z-index: 5;
    }
  }

  > label {
    padding-right: 78px;
    display: block;
    font-family: var(--font-family-secondary);
    font-size: 16px;
    line-height: 24px;
  }
}
