@import "styles/constants";

.mobea-cookies {
  @include fullscreen-page;
  height: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;

  font-size: 14px;
  line-height: 24px;

  &__more {
    text-align: center;
    font-family: var(--font-family-secondary);
    line-height: 20px;
    margin-bottom: 12px;
  }

  &__section {
    padding-top: 30px;
    padding-bottom: 17px;
    position: relative;

    label {
      font-size: 16px;
      font-weight: 500;
      line-height: 32px;
      font-family: inherit;
    }

    &__required {
      text-transform: lowercase;
      font-weight: 400;
    }

    .mobea__cookies__details__section__content {
      color: var(--color-gray-300);
      font-family: inherit;
      line-height: 24px;
    }

    &:after {
      content: " ";
      width: calc(100% + 64px);
      height: 1px;
      margin: 0 -32px;
      background-color: var(--color-background);
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
