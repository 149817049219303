.mobea-option-loader {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding-top: 8px;
  overflow: hidden;

  .icon-placeholder {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: var(--color-gray-100);
  }

  .name-info-placeholders {
    flex: 1 0;
    margin-left: 16px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--color-background);

    &__name {
      background-color: var(--color-gray-100);
      height: 20px;
      max-width: 200px;
    }

    &__info {
      margin-top: 4px;
      background-color: var(--color-gray-100);
      height: 12px;
      max-width: 80px;
    }
  }

  &::before {
    content: "";
    position: absolute;
    height: 60px;
    width: 100%;
    top: calc(50% - 30px);
    left: -100%;
    background: rgba(white, 0.8);
    background: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0.7) 80%,
      rgba(255, 255, 255, 0.3) 100%
    );
  }

  &::after {
    content: "";
    position: absolute;
    height: 60px;
    width: 100%;
    top: calc(50% - 30px);
    left: -100%;
    margin-left: 100%;
    background: rgba(white, 0.8);
    background: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0.7) 80%,
      rgba(255, 255, 255, 0.3) 100%
    );
  }

  &.running::before,
  &.running::after {
    animation: move-shimmer 1.5s linear infinite;
  }
}

@keyframes move-shimmer {
  0% {
    left: -120%;
  }
  100% {
    left: 0;
  }
}
