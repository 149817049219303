.mobea__cookies {
  .mobea__modal__dialog {
    max-height: calc(100% - 32px);
    overflow-y: auto;
    padding-bottom: 24px;
  }

  &__text {
    margin-bottom: 24px;
  }

  &__more {
    border: 0 none;
    background-color: transparent;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: var(--color-primary);
    padding: 0;
    outline-color: transparent;
    
    &::first-letter{
      text-transform: uppercase;
    }

    > svg {
      margin-left: 8px;
      display: inline-block;
      position: relative;
      top: -1px;
      stroke-width: 1px;
      stroke: currentColor;
    }

    &.mobea__collapsed {
      > svg {
        transform: rotate(180deg);
      }
    }
  }

  &__separator {
    margin: 24px 0;
    background-color: var(--color-gray-200);
    height: 1px;
    border: 0 none;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__checkboxes {
    .mobea__checkbox-group {
      height: 24px;

      &:not(:last-child) {
        margin-bottom: 24px;
      }

      label {
        font-family: var(--font-family-secondary);
      }
    }
  }

  &__details {
    text-align: left;
    margin-top: 24px;

    &__section {
      margin-bottom: 32px;

      &:last-of-type {
        margin-bottom: 0;
      }

      &__title {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        margin: 0 0 16px 0;
      }

      &__content {
        font-family: var(--font-family-secondary);
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &__buttons {
    padding-top: 16px;

    &__selected {
      margin-bottom: 0;
    }
  }
}
