.mobea-history__expense {
  .mobea-history__costs__spending {
    &__logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 40px;

      svg,
      img {
        max-width: 40px;
        max-height: 40px;
        pointer-events: none;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;

        &.mobea__delijn {
          width: auto;
          height: 36px;
        }

        &.mobea__nmbs {
          width: 40px;
          height: 24px;
        }

        &.mobea__lime {
          width: 50px;
          margin-left: -10px;
        }

        &.mobea__verts {
          width: 40px;
          height: auto;
        }

        &.mobea__mivb {
          height: 33px;
          width: auto;
        }

        &.mobea__wheels {
          width: 52px;
          margin-left: -8px;
        }

        &.mobea__poppy {
          width: 54px;
          margin-left: -8px;
        }
      }

      img {
        height: auto;
      }
    }

    &__price {
      &__accepted-partial {
        display: block;

        &__accepted {
          display: block;
          margin-bottom: 2px;
          color: var(--color-primary);
        }

        &__requested {
          display: block;
          color: var(--color-gray-200);
          text-decoration: line-through;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }

    &__status {
      width: 24px;
      height: 24px;
      margin: 8px 0 8px 16px;
      padding: 0;
    }
  }
}
