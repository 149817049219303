.mobea__verts-order-slider {  
  padding-top: 4px;

  .mobea-bottom-slider {
    &__title {
      line-height: 24px;
      padding-top: 4px;
    }

    &__close-icon,
    &__back-icon {
      top: 38px;
    }

    &__content {
      padding: 16px 0 0;
    }
  }

  &__scroll-container {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
  }

  .mobea__steps {
    display: flex;
    justify-content: center;
  }

  .mobea__source-destination {
    margin-bottom: 28px;

    .mobea__input-group__error {
      bottom: initial;
      top: calc(100% + 4px);
      white-space: normal;
      font-size: 12px;
      line-height: 1;
    }
  }

  .mobea__travel-time {
    margin: 0 -24px;
  }

  &__bottom-section {
    flex: 1 0 110px;
    padding: 16px 0;
    position: relative;
    display: flex;

    &__message-area {
      &__info {
        display: flex;
        align-items: flex-start;
        border: 1px solid var(--color-blue);
        border-radius: 2px;
        background-color: rgba(0, 35, 156, 0.08);
        padding: 8px 12px;
        color: var(--color-blue);
        font-size: 12px;
        letter-spacing: 0;
        line-height: 20px;
        animation: fadeIn 0.5s linear;

        svg {
          flex-shrink: 0;
          margin-right: 10px;
        }
      }
    }

    &__vehicle-options {
      position: relative;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      width: 100%;

      &__title {
        font-size: 14px;
        font-weight: bold;
      }

      &__spinner {
        position: relative;
        flex: 1 1 auto;
        width: 100%;
      }

      &__packages {
        display: flex;
        flex-wrap: wrap;
        margin-top: 12px;
      }

      &__estimation-info {
        color: var(--color-gray-300);
        font-family: var(--font-family-secondary);
        font-size: 12px;
        line-height: 20px;
        margin-top: 8px;
      }
    }
  }

  &__button {
    &__price {
      margin-bottom: 4px;
      overflow: hidden;
      color: var(--color-gray-400);
      font-family: var(--font-family-numbers);
      font-size: 15px;
      line-height: 24px;

      &__label {
        float: left;
      }

      &__value {
        float: right;
        font-weight: 600;
      }
    }
  }
}
