.mobea__travel-pass__header__provider {
  flex: 1 1 auto;
  margin: 0 15px 0 16px;
  color: #414141;
  text-align: left;
  padding-top: 2px;

  &__name {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }

  &__dates {
    color: var(--color-gray-200);
    font-family: var(--font-family-numbers);
    font-size: 12px;
    line-height: 16px;
    margin: 0;

    .return-icon {
      transform: rotate(90deg);
      height: 16px;
      width: 16px;
      vertical-align: bottom;
      color: var(--color-primary);
      margin: 0 12px;

      @media screen and (max-width: 360px) {
        margin: 0 6px;
      }
    }
  }
}
