.mobea-providers {
  &.m_page {
    @media screen and (max-width: 360px) {
      padding-left: 12px;
      padding-right: 12px;
    }

    @media screen and (max-width: 320px) {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  .m_page__header {
    margin-left: 8px;

    &__title {
      flex-grow: 1;
    }
    .search-icon {
      margin-right: 8px;
      float: right;
      cursor: pointer;
      transition: opacity 0.2s;

      &.hidden {
        opacity: 0;
      }
    }
  }

  &__search-field {
    margin-bottom: 16px;
    max-height: 60px;
    transition: max-height 0.2s, margin-bottom 0.2s, opacity 0.2s;
    overflow: hidden;

    &.hidden {
      max-height: 0;
      margin-bottom: 0;
      opacity: 0;
    }
  }

  .mobea-providers-filter {
    margin-bottom: 12px;
    margin-left: -12px;
    margin-right: -12px;
    padding-left: 12px;
    padding-right: 12px;
  }

  &__providers {
    overflow: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    place-items: center;
    padding-top: 4px;
    padding-bottom: 8px;
    grid-row-gap: 8px;
    column-gap: 16px;
    row-gap: 16px;
    justify-content: space-around;

    @media screen and (max-width: 320px) {
      column-gap: 8px;
    }

    @media screen and (max-width: 360px) {
      grid-row-gap: 10px;
      row-gap: 10px;
      column-gap: 12px;
    }

    @media screen and (min-width: 420px) {
      grid-row-gap: 24px;
      row-gap: 24px;
    }

    @media screen and (min-width: 500px) {
      grid-template-columns: repeat(4, 25%);
    }

    @media screen and (min-width: 650px) {
      grid-template-columns: repeat(5, 20%);
    }
  }
}
