.mobea-expense-detail {
  .m_page__header {
    padding: 0 24px;
  }

  &__content {   
    background-color: var(--color-background);
    padding-bottom: 32px;
  }

  &__form {
    background-color: white;
    padding: 16px 32px;

    &__row {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    &__providers {
      width: 100%;
      margin-bottom: 20px;

      > label {
        line-height: 1;
      }

      .mobea__dropdown {
        width: 100%;

        &__select {
          border-bottom: 1px solid var(--color-gray-100);
          height: 36px;
          padding-top: 4px;

          &__value {
            line-height: 24px;
            font-size: 16px;
            font-family: var(--font-family-secondary);
            height: 100%;
            display: inline-block;
          }
        }

        &.mobea__disabled {
          color: var(--color-gray-300);

          .mobea__dropdown__select {
            border-bottom: 0 none;
          }
        }
      }
    }

    .mobea__input-group {
      width: calc(50% - 16px);
      border-bottom: 0 none;

      > input:disabled {
        color: var(--color-gray-300);
        background-color: transparent;
        opacity: 1;
      }
    }

    &__date {
      margin-right: 32px;
    }

    &__price {
      &__euro {
        position: absolute;
        top: 20px;
        left: 0;
        line-height: 24px;
        font-size: 16px;
        color: var(--color-gray-500);
        height: 31px;
        font-family: var(--font-family-numbers);
      }
    }
  }

  &__uploads {
    flex: 1 1 auto;
    padding-left: 32px;
    padding-right: 24px;

    &__label {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
    }

    &__previews {
      clear: both;
      overflow: auto;
      position: relative;
      min-height: 80px;

      &__preview {
        width: calc(50% - 12px);
        overflow: hidden;
        border-radius: 8px;
        position: relative;
        float: left;
        margin: 12px 1px;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.48);
        background-color: white;
        color: var(--color-primary);

        &__spacer {
          width: 100%;
          padding-top: 100%;
          height: 0;
        }
        img,
        a {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 50;
        }

        img {
          object-fit: cover;
          object-position: center;
          z-index: 10;
          background-color: white;
          pointer-events: none;

          &.mobea__image__error {
            visibility: hidden;
          }
        }

        > svg {
          position: absolute;
          top: -10px;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto;
        }

        &__name {
          position: absolute;
          left: 0;
          width: 100%;
          bottom: 12%;
          margin: 0;
          padding: 0 5%;
          font-size: 13px;
          line-height: 24px;
          font-weight: 500;
          display: flex;
          flex-direction: row;
          height: 20px;
          justify-content: center;

          > span {
            flex: 0 0 auto;
            display: inline-block;

            &:first-child {
              flex: 0 1 auto;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }

        &:nth-child(2n) {
          margin-left: 12px;
        }
      }
    }
  }

  &__status {
    background: var(--color-ticket-background);
    margin: 24px 32px 32px;
    padding: 16px 8px;

    &__headline {
      margin: 0 16px 16px;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: var(--color-primary);

      .mobea__status {
        display: inline-block;
        vertical-align: sub;
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }

      .mobea__status__denied ~ span {
        color: var(--color-error);
      }
      .mobea__status__pending ~ span {
        color: var(--color-gray-300);
      }
    }

    &__text {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      margin: 0;

      &__contact_us {
        color: var(--color-primary);
        text-decoration: underline;
      }
    }
  }

  &__fullscreen-preview {
    background-color: var(--color-gray-500);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    align-items: center;

    &__close.mobea__close-button {
      position: absolute;
      top: 20px;
      right: 20px;
      background-color: rgba(255, 255, 255, 0.85);
      border-radius: 50%;
      z-index: 10;
    }

    img {
      object-fit: contain;
      object-position: center;
      pointer-events: none;
      width: 100%;
      position: relative;
      z-index: 1;
    }
  }
}
