@import "styles/constants";

.mobea-tutorials {
  @include fullscreen-page;
  padding: 32px;
  display: flex;
  flex-direction: column;

  &__content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    & > * {
      height: 72px;
      padding: 16px 16px 16px 0;
      text-decoration: none;
      font-family: var(--font-family);
      color: var(--color-gray-500);
      font-size: 16px;
      display: flex;
      border-bottom: 1px solid var(--color-background);
      align-items: center;

      & > *:first-child {
        flex-grow: 1;
      }

      & > *:last-child {
        transform: rotate(90deg);
        color: var(--color-gray-200);
      }
    }
  }

  &__empty-space {
    flex: 1 1 auto;
    min-height: 32px;
  }
}
