.mobea__phone-number-picker {
  display: flex;
  align-items: center;
  border: 1px solid var(--color-gray-100);
  border-radius: 4px;
  padding: 8px 12px;

  &.disabled {
    background-color: var(--color-disabled-bg);

    & input {
      background-color: var(--color-disabled-bg);
    }
  }

  &__focused {
    border: 1px solid var(--color-primary);
    outline: 1px solid var(--color-primary);
  }

  &__prefix,
  &__number {
    border: none;
    font-size: 16px;
    font-family: var(--font-family-numbers);
    line-height: 32px;
    letter-spacing: 2px;
    display: block;
    height: auto;

    @media screen and (max-width: 370px) {
      letter-spacing: 2px;
    }
  }

  &__prefix {
    flex: 0 0;

    .mobea__dropdown__select {
      height: 32px;

      &__value {
        font-size: 16px;
        color: var(--color-gray-500);
        line-height: 32px;
      }

      &__arrow {
        display: none;
      }
    }

    .mobea__dropdown__options {
      padding: 0 4px;
      margin-top: 8px !important;
      margin-left: -13px;
      width: 96px;
      box-shadow: none;
      border-radius: 2px;
      border: none;

      .mobea__dropdown__option {
        font-size: 16px;
        color: var(--color-gray-500);
      }

      &.dropdown-enter-done,
      &.dropdown-enter-active,
      &.dropdown-exit-active {
        border: 1px solid var(--color-gray-100);
      }
    }
  }

  &__number {
    flex: 1 1 auto;
    min-width: 100px;
    border-left: 1px solid var(--color-gray-100);
    padding: 0 0 0 16px;
    margin-left: 16px;
    outline: none;
    height: 32px;
    line-height: normal;
    color: var(--color-gray-500);
  }

  &.mobea__invalid {
    .mobea__phone-number-picker {
      &__prefix,
      &__number {
        border-bottom-color: var(--color-gray-200);
      }
    }
  }
}
