.mobea__datepicker-modal {
  .mobea__modal__dialog {
    &__header {
      &:after {
        content: " ";
        position: absolute;
        left: 0;
        height: 4px;
        bottom: 0;
        box-shadow: 0 4px 6px rgba(128, 128, 128, 0.3);
        width: calc(100% + 64px);
        margin-left: -32px;
      }
    }

    &__content {
      margin-left: -32px;
      margin-right: -32px;
      padding: 16px 24px;
      position: relative;

      @media screen and (max-width: 370px) {
        padding: 16px 4px;
      }
    }

    &__footer {
      padding-top: 32px;
      position: relative;

      &:before {
        content: " ";
        position: absolute;
        left: 0;
        width: calc(100% + 64px); /* supress margin on footer */
        height: 4px;
        top: 0;
        box-shadow: 0 -4px 6px rgba(128, 128, 128, 0.3);
        margin-left: -32px;
      }
    }
  }
}
