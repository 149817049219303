.mobea-mobit-order {
  &__logo {
    text-align: center;
    margin: 24px 0;

    > svg {
      height: 32px;
    }
  }

  &__ticket {
    flex: 1 1 auto;
    background-color: var(--color-background);
    margin-left: -24px;
    margin-right: -24px;
    padding: 0 32px;
    margin-bottom: -32px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;

    &__details {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      text-align: center;

      &__name {
        color: #fccd00;
        font-size: 34px;
        line-height: 40px;
        margin: 0;
        font-weight: 500;
        margin-bottom: 12px;
        margin-top: 32px;

        &__green {
          color: var(--color-mobit);
        }
      }

      &__info {
        font-size: 16px;
        text-align: center;
        color: var(--color-gray-300);
        line-height: 26px;
        margin: 0;
      }
    }
  }

  .mobea__modal__dialog__image > svg {
    color: var(--color-primary);
  }

  .mobea__arial {
    white-space: nowrap;
  }

  .font-reset {
    font-family: var(--font-family);
  }
}
