.mobea-update-checker {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 8px 8px;
  background: var(--color-gray-200);
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  transform: translateY(-200px);
  transition: transform 0.5s;
  z-index: 1000;

  > svg {
    margin-right: 4px;
    margin-top: -2px;
  }

  &.visible {
    transform: translateY(0);
  }
}
