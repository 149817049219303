.mobea__label-value {
  &__icon-label {
    display: flex;
    align-items: center;
  }

  &__icon {
    color: var(--color-gray-200);
    margin-top: 4px;
    margin-right: 4px;
  }

  &__label {
    color: var(--color-gray-200);
    font-family: var(--font-family-secondary);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 24px;
  }

  &__value {
    color: var(--color-gray-300);
    font-family: var(--font-family-secondary);
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;

    @media screen and (max-width: 360px) {
      font-size: 14px;
    }
  }
}
