.mobea__verts-order-summary {
  width: 100%;
  padding-top: 32px;

  &__image {
    width: 178px;
    height: 64px;
    display: block;
    margin: 0 auto 40px auto;
  }

  &__time {
    font-family: var(--font-family-numbers);
    overflow: hidden;
    margin-bottom: 12px;

    &__depart {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      float: left;
      margin: 0;
    }
    &__length {
      float: right;
      color: var(--color-gray-200);
      font-size: 12px;
      line-height: 24px;
    }
  }

  &__route {
    position: relative;
    padding-left: 28px;

    > svg {
      position: absolute;
      left: 0;
      top: 8px;
      color: var(--color-primary);
    }

    &__address {
      margin: 0;
      font-family: var(--font-family-numbers);
      font-size: 14px;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:first-of-type {
        margin-bottom: 8px;
      }
    }
  }
}
