.mobea__ios-install {
  position: absolute;
  bottom: 15px;
  left: 5%;
  width: 90%;
  padding: 16px;
  z-index: 1;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);

  &:after {
    content: " ";
    position: absolute;
    top: 100%;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 12px solid white;
    width: 0;
    height: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 2;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.48);
  }

  &__header {
    &__logo {
      width: 48px;
      height: 48px;
      background-color: var(--color-background);
      margin: 0 auto;
      border-radius: 12px;
      position: relative;

      > img,
      svg {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 48px;
        height: 48px;
        margin: auto;
      }
    }

    &__text {
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      font-weight: 500;
      margin-top: 24px;
      margin-bottom: 12px;
    }
  }

  &__content {
    p {
      font-size: 14px;
      line-height: 21px;
      margin: 4px 0;
      text-align: center;
    }
  }
  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 29px;
    height: 29px;
    border: 0 none;
    background-color: transparent;

    &:before,
    &:after {
      content: " ";
      width: 20px;
      position: absolute;
      height: 2px;
      top: 14px;
      left: 4px;
      background-color: var(--color-primary);
      transform: rotate(45deg);
      transform-origin: center;
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  &__add {
    color: var(--color-primary);
    margin-top: 16px;
    display: inline-block;
  }
}
