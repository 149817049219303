.mobea__mobit-ticket__info__item {
  margin: 0;
  padding: 0;

  &__label {
    font-family: var(--font-family-secondary);
    font-size: 12px;
    line-height: 16px;
    color: var(--color-gray-200);
    display: block;
  }
  &__value {
    line-height: 20px;
    font-size: 16px;
    display: block;
    margin-top: 4px;
  }
}
