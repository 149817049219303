.mobea-search-field {
  .mobea__input-group {
    float: none;
    position: relative;
    background: white;
    border-bottom: none;
    padding: 2px 40px;
    height: auto;
  }

  &__search-icon {
    position: absolute;
    left: 10px;
    top: 8px;
    color: var(--color-gray-200);
  }

  .mobea__close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
