.mobea__language {
  position: absolute;
  bottom: 0;
  background-color: transparent;
  border: 0 none;
  color: var(--color-text-inverted);
  height: 40px;
  line-height: 24px;
  text-align: center;
  width: 100%;
  left: 0;
  z-index: 1010;

  &.mobea__expanded {
    color: var(--color-primary);

    .mobea__language__current {
      background-color: white;
    }
  }

  &__current {
    width: 100%;
    height: 100%;
    transition: background-color 300ms, color 300ms;
    padding-bottom: 16px;
    position: relative;
    z-index: 5;

    &__globe-icon {
      position: relative;
      top: 2px;
      margin-right: 8px;
    }

    &__arrow-icon {
      position: relative;
      margin-left: 8px;
      top: -2px;
    }
  }

  &__options {
    padding: 0;
    margin: 0;
    width: 100%;
    position: absolute;
    left: 0;
    text-align: center;
    background-color: white;
    overflow: hidden;
    height: auto;
    padding-top: 16px;
    transition: bottom 300ms linear;

    &.mobea__expanded {
      z-index: 1;
    }
  }

  &__option {
    list-style: none;
    height: 24px;
    margin-bottom: 16px;
    line-height: 24px;

    > span {
      position: relative;
    }
    svg {
      position: absolute;
      right: -24px;
      top: 2px;
      color: var(--color-error);
    }
  }
}
