.mobea-autocomplete-field {
  position: relative;

  input {
    padding-right: 32px;
  }

  .back-icon {
    position: absolute;
    left: 12px;
    top: 11px;
  }

  .icon-container {
    position: absolute;
    right: 12px;
    top: 12px;
    display: flex;
    align-items: flex-start;

    &__icon {
      color: var(--color-gray-200) !important;
    }

    .icon-separator {
      height: 22px;
      width: 1px;
      margin: 0 8px;
      background-color: var(--color-gray-100);
    }

    .mobea__spinner {
      position: relative;
      margin-right: 6px;
    }
  }

  .search-icon {
    right: 12px;
  }

  &__options {
    position: absolute;
    top: 100%;
    border-top: 1px solid var(--color-gray-100);
    z-index: 100;
    background: white;
    width: 100%;
    overflow-y: hidden;
    box-shadow: 1px 3px 3px 0 rgba(0, 0, 0, 0.24);

    .option {
      padding: 12px 0;
      font-family: var(--font-family-secondary);
      font-size: 12px;

      &:first-of-type {
        padding-top: 16px;
      }

      &:last-of-type {
        padding-bottom: 16px;
      }
    }
  }

  &.back-visible {
    input {
      padding-left: 34px;
    }
  }

  &.clear-visible {
    input {
      padding-right: 70px;
    }
  }
}
