.mobea-add-expense {
  .m_page__header {
    padding: 0 24px;
  }

  &__content {
    background-color: var(--color-background);
    padding-bottom: 32px;
  }

  &__form {
    background-color: white;
    padding: 0 24px 32px 24px;

    &__providers {
      width: 100%;
      margin-bottom: 20px;

      > label {
        line-height: 1;
      }

      .mobea__dropdown {
        width: 100%;

        &__select {
          border-bottom: 1px solid var(--color-gray-100);
          height: 36px;
          padding-top: 4px;

          &__value {
            line-height: 24px;
          }
        }

        &__option {
          height: 48px;
          padding: 12px 16px;
          font-size: 16px;
          line-height: 24px;
          font-family: var(--font-family-secondary);
          font-weight: 500;
          color: var(--color-gray-500);

          .mobea-add-expense__form__providers__option {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 24px;
            
            svg{
              height: 24px;
            }

            svg,
            img {
              max-height: 24px;
              pointer-events: none;
              image-rendering: -webkit-optimize-contrast;
              image-rendering: crisp-edges;


              &.mobea__delijn {
                height: 26px;
                max-height: 26px;
              }

              &.mobea__nmbs {
                height: 18px;
              }

              &.mobea__mobit {
                height: 18px;
              }

              &.mobea__lime {
                height: 20px;
                margin-top: 2px;
              }

              &.mobea__mivb {
                height: 28px;
                max-height: 28px;
              }

              &.mobea__verts {
                height: 20px;
              }

              &.mobea__li-bia-velo {
                max-height: 30px;
                height: 30px;
              }

              &.mobea__uber {
                height: 18px;
              }

              &.mobea__tec {
                height: 16px;
              }

              &.mobea__bird {
                height: 13px;
              }

              &.mobea__dott {
                height: 17px;
              }

              &.mobea__poppy {
                height: 20px;
              }

              &.mobea__wheels {
                height: 17px;
              }

              &.mobea__blue-bike {
                max-height: 28px;
              }

              &.mobea__donkey-republic{
                max-height: 28px;
                height: 28px;
              }
            }
          }
        }
      }

      &.mobea__active {
        > label {
          color: var(--color-primary);
        }

        .mobea__dropdown {
          &__select {
            border-bottom: 2px solid var(--color-primary-light);

            &__arrow {
              color: var(--color-primary);
            }
          }
        }
      }
    }

    .mobea__input-group {
      width: calc(50% - 16px);
    }

    &__date {
      margin-right: 32px;
    }

    &__price {
      &__euro {
        position: absolute;
        top: 21px;
        left: 0;
        line-height: 24px;
        font-size: 16px;
        color: var(--color-gray-500);
        height: 31px;
        font-family: var(--font-family-numbers);
      }
    }
  }

  &__uploads {
    flex: 1 1 auto;
    padding: 20px 24px 8px 24px;
  }

  &__button {
    padding: 0 24px;
  }

  .DayPicker {
    width: 100%;

    &-Month {
      width: 100%;
      max-width: 280px;
      margin: 0;
    }
  }
}
