@font-face {
  font-family: "DaxlinePro";
  src: url("/static/fonts/DaxlinePro-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DaxlinePro";
  src: url("/static/fonts/DaxlinePro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DaxlinePro";
  src: url("/static/fonts/DaxlinePro-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
