@import "styles/maps";

.velo-map {
  .m_page__header {
    z-index: 20;
    padding: 20px 24px 16px;

    .mobea-ticket-detail__info {
      width: 24px;
      height: 24px;
      position: static;
      padding: 0;
    }
  }

  .search-location-field-container {
    @include search-location-field-container();
    margin: 32px 24px;
  }
  
  .mobea-bottom-slider {
    &__title {
      font-family: var(--font-family-numbers);
    }

    &__content {
      width: 100%;
      padding-right: 0;
      padding-left: 0;
    }
  }
}
