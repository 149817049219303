.mobea-mobit-report {
  /* base class for nested page content */
  &__content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    background-color: var(--color-background);
    margin-left: -32px;
    margin-right: -32px;
    margin-bottom: -32px;
    padding: 0 32px 32px 32px;
  }

  &__form {
    background-color: white;
    padding: 32px;
    margin: 0 -32px;

    .mobea__input-group {
      width: 100%;
      margin-bottom: 20px;
      float: none;

      &__error {
        right: 0;
        left: initial;
        bottom: 8px;
      }
    }

    &__problem {
      width: 100%;

      > label {
        line-height: 1;
      }

      .mobea__dropdown {
        &__select {
          &__value {
            line-height: 24px;
          }
        }
      }

      &.mobea__active {
        > label {
          color: var(--color-primary);
        }

        .mobea__dropdown {
          &__select {
            border-bottom: 2px solid var(--color-primary-light);

            &__arrow {
              color: var(--color-primary);
            }
          }
        }
      }
    }
  }

  &__uploads {
    padding-top: 32px;
    flex: 1 1 auto;
  }
}
