.mobea__mobit-ticket__start-end {
  clear: both;
  float: none;
  display: flex;
  flex-direction: row;

  &__separator {
    flex: 1 1 auto;
    position: relative;

    > svg {
      color: var(--color-primary);
      transform: rotate(180deg);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
}

.mobea__mobit-ticket__info__item {
  &__from,
  &__to {
    flex: 0 0 auto;

    .mobea__mobit-ticket__info__item__value {
      font-family: var(--font-family-numbers);

      @media screen and (max-width: 360px) {
        font-size: 14px;
      }
    }
  }
}
