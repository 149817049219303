.car-plate {
  color: var(--color-red);
  background-color: white;
  font-family: var(--font-family-numbers);
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 20px;
  height: 28px;
  display: flex;
  align-items: center;
  padding-right: 12px;
  border-radius: 2px;
  border: 1px solid var(--color-red);
  white-space: nowrap;

  &__country {
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    color: white;
    width: 24px;
    background-color: #5c99ff;
    margin-right: 8px;
  }
}
