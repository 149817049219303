.mobea-order-nmbs {
  &__steps {
    justify-content: center;
    margin-top: 16px;
  }

  &__route {
    height: 108px;
    margin-top: 24px;
    margin-bottom: 32px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    &__icon {
      flex: 0 0 auto;
      display: block;
      width: 14px;
      height: 70px;
      color: var(--color-primary);
      margin-right: 22px;

      @media screen and (max-width: 360px) {
        margin-right: 12px;
      }
    }

    &__form {
      flex: 1 1 auto;
      height: 100%;

      .mobea__input-group {
        width: 100%;

        > input {
          text-transform: capitalize;
        }
      }

      &__from {
        margin-bottom: 6px;
      }
    }

    &__reverse {
      flex: 0 0 auto;
      width: 30px;
      height: 30px;
      padding: 3px;
      background-color: transparent;
      color: var(--color-primary);
      margin: 0;
      margin-left: 16px;
      border: 0 none;
    }
  }

  &__ticket {
    flex: 1 1 auto;
    background-color: var(--color-background);
    margin-left: -24px;
    margin-right: -24px;
    padding: 32px;
    margin-bottom: -24px;
    display: flex;
    flex-direction: column;

    &__trip {
      flex: 1 1 auto;

      .mobea__tab-pane-nav {
        margin-bottom: 24px;
      }

      .mobea__input-group {
        width: 100%;
        float: none;
        background-color: white;
        padding-left: 16px;
        border-radius: 2px;
        border-bottom-width: 0 !important;

        > input {
          padding-top: 6px;
        }

        &.mobea__empty {
          padding-top: 6px;
        }

        > label {
          left: 16px;
          pointer-events: none;
        }
      }

      &__age-dropdown {
        background: white;
        margin-top: 16px;
        padding: 4px 12px;
        display: none;

        > label {
          top: 4px;
          left: 12px;
        }

        .mobea__dropdown {
          margin-top: 12px;

          &__select {
            border: none;
          }

          &__options {
            left: -12px;
            right: -12px;
            width: auto;

            &.dropdown-enter-done,
            &.dropdown-enter-active,
            &.dropdown-exit-active {
              box-shadow: none;
            }
          }

          &__option {
            border-bottom: 1px solid var(--color-background);
          }

          &.mobea__direction__down {
            .mobea__dropdown__options {
              margin-top: 0;
            }
          }
        }
      }

      &__travel-class {
        display: flex;
        width: 100%;
        align-items: center;
        margin-top: 24px;

        .mobea__checkbox-group {
          &:not(:first-of-type) {
            margin-left: 40px;
          }
        }
      }
    }
  }

  .mobea__modal__dialog__image > svg {
    color: var(--color-primary);
  }

  .mobea__order__info__hidden {
    .mobea__order__ticket__footer__info {
      visibility: hidden;
    }
  }

  &__choose-ticket {
    flex: 1 1 auto;
    margin: 32px -24px -24px;
    background-color: var(--color-background);
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;

    &__tickets {
      flex: 1 1 auto;

      &__ticket {
        background: white;
        padding: 24px 32px;
        margin-top: 2px;

        .check-name-info {
          display: flex;
          align-items: center;
          height: 28px;

          .mobea__checkbox-group {
            flex: 1 1 auto;
          }

          &__name {
            flex-grow: 1;
            color: var(--color-gray-500);
            font-size: 20px;
            font-weight: bold;
          }

          &__icon {
            position: relative;
            width: 40px;
            height: 40px;
            right: -8px;
            padding: 8px;
          }
        }

        .description-price {
          display: flex;
          justify-content: space-between;
          padding-left: 40px;
          margin-top: 12px;

          &__description {
            max-width: 200px;
            color: var(--color-gray-300);
            font-family: var(--font-family-secondary);
            font-size: 16px;
            line-height: 24px;
          }

          &__price {
            color: var(--color-gray-500);
            font-family: var(--font-family-numbers);
            font-size: 16px;
          }
        }
      }
    }

    &__no-result {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 100px 32px 32px;

      &__text {
        margin-top: 24px;
        color: var(--color-gray-300);
        font-size: 14px;
        line-height: 24px;
        text-align: center;
      }

      &__change-parameters {
        margin-top: 24px;
        color: var(--color-primary);
        font-size: 20px;
        font-weight: bold; 
        line-height: 24px;
        text-align: center;
        display: inline-block;
        &::first-letter{
          text-transform: uppercase;
        }
      }
    }

    &__confirm-button {
      margin-left: 32px;
      margin-right: 32px;
    }
  }

  &__ticket-success-modal {
    .mobea__info {
      padding-bottom: 16px;
    }
  }
}
