.mobea__dropdown {
  display: block;
  height: 38px;
  position: relative;
  overflow: visible;
  float: left;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }


  &__select {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    &__prefix {
      margin-right: 8px;
    }

    &__arrow {
      pointer-events: none;
      color: var(--color-primary);
      margin-left: 8px;
    }

    &__value {
      font-size: 20px;
      line-height: 34px;
    }
  }

  &.mobea__direction__down {
    .mobea__dropdown__select__arrow {
      transform: rotate(180deg);
    }
  }

  &__options {
    position: absolute;
    width: 100%;
    height: 0;
    left: 0;
    z-index: 555;
    margin: 0;
    padding: 0;
    background-color: white;
    max-height: 190px; /* default height of 5 items */
    transition: height 300ms ease-in;
    border: 1px solid transparent;
    pointer-events: none;
    overflow: hidden;

    &.dropdown-enter-done,
    &.dropdown-enter-active,
    &.dropdown-exit-active {
      box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.48);
      height: auto;
      pointer-events: all;
      border: 1px solid currentColor;
    }

    &.dropdown-enter-done.mobea__dropdown__scroll {
      overflow: scroll;
    }
  }

  &.mobea__direction__down {
    .mobea__dropdown__options {
      top: 100%;
      margin-top: 1px;
    }
  }

  &.mobea__direction__up {
    .mobea__dropdown__options {
      bottom: 100%;
    }
  }

  &__option {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 2px;
    height: 38px;
  }

  &.mobea__disabled {
    .mobea__dropdown__select__arrow {
      display: none;
    }
  }
}
