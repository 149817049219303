@import "styles/constants.scss";

.mobea-history {
  @include fullscreen-page;
  background-color: var(--color-background);
  padding: 32px;
  display: flex;
  flex-direction: column;

  &.app-moveasy {
    padding-bottom: 0;
  }

  &__header {
    width: 100%;
    position: relative;
    margin-bottom: 32px;

    > h1 {
      color: var(--color-gray-500);
      font-size: 22px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      margin: 0;
      margin-bottom: 24px;
    }
  }

  &__tab-container {
    flex: 1 1 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 -32px 60px; /* allow to scroll so not covered by button */
    margin-bottom: calc(60px + env(safe-area-inset-bottom, 0px));
  }

  &__costs {
    margin: 0;
    padding: 0;
    position: relative;
    flex: 1 0 auto;

    &__block {
      margin: 0;
      padding: 0;
      position: relative;

      &:before {
        content: " ";
        position: absolute;
        top: 30px;
        left: -16px;
        height: calc(100% + 0px);
        width: 2px;
        background-color: var(--color-gray-100);
      }

      &:last-of-type {
        &:before {
          opacity: 0;
        }
      }

      &:first-of-type {
        &:before {
          top: 20px;
          height: calc(100% + 10px);
        }

        .mobea-history__costs__date {
          height: 36px;
          padding-top: 12px;
        }
      }
    }

    &__title {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      margin: 0;
      text-transform: uppercase;
      margin-left: -20px;
    }

    &__date {
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      height: 48px;
      padding: 24px 0 8px 0;
      list-style: none;
      text-transform: uppercase;
      font-family: var(--font-family-numbers);
      color: #69696a;

      &:before {
        content: " ";
        position: absolute;
        left: -20px;
        border: 2px solid var(--color-gray-200);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #fff;
        margin-top: 2px;
        z-index: 1;
      }

      &.today {
        &:before {
          border-color: var(--color-primary);
        }
      }
    }

    &__spending {
      list-style: none;
      width: 100%;
      min-height: 72px;
      display: flex;
      background-color: white;
      border-bottom: 1px solid var(--color-background);
      padding: 16px 32px 16px 12px;

      @media screen and (max-width: 360px) {
        padding: 8px;
      }

      &__price {
        padding: 10px 0 2px 0;
        margin: 0;
        height: 100%;
        line-height: 20px;
        font-size: 16px;
        text-align: right;
        font-weight: 600;
        font-family: var(--font-family-numbers);
      }

      &__declined {
        color: var(--color-error);
      }

      &__pending {
        color: var(--color-gray-200);
      }

      &__accepted {
        color: var(--color-primary);
      }
    }
  }

  &__loading-overlay {
    position: absolute;
    top: -32px;
    left: -32px;
    width: calc(100% + 32px * 2);
    height: calc(100% + 64px);

    > .mobea__spinner {
      position: fixed;
    }
  }
}
