.mobea__multi-ticket-detail {
  &__info {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0 2px 4px 2px;
    font-weight: normal;

    > svg {
      height: 100%;
      width: auto;
    }
  }

  &__tickets {
    margin-left: -24px;
    margin-right: -24px;
  }
}
