.mobea__offline-banner {
  position: fixed;
  top: -80px;
  left: 0;
  width: 100%;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
  z-index: 9999;
  text-align: center;
  color: var(--color-text-inverted);
  transition: top 0.3s;

  &.active {
    top: 0;

    > div {
      padding: 8px;
      height: 36px;

      &.incident {
        height: auto;
        max-height: 70px;
        background-color: var(--color-error);
        padding: 6px;

        > svg {
          display: inline-block;
          position: absolute;
          top: 14px;
          left: 10px;
          height: 24px;
          width: 24px;
          margin: 0;
        }

        > span {
          line-height: 20px;
          margin-left: 34px;
          text-align: left;
        }
      }

      &.offline {
        background-color: var(--color-gray-200);
      }
    }
  }

  &__icon {
    display: inline-block;
    height: 20px;
    margin-right: 8px;
  }

  &__text {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    display: inline-block;
    vertical-align: super;
  }

  &.active ~ div > main {
    top: 24px;
    min-height: calc(100% - 24px); /* reduce top padding */
  }
}
