.mobea__progress-bar {
  position: relative;
  width: 100%;
  height: 24px;
  background: #e9e9e9;
  border-radius: 4px 2px 2px 4px;

  &__progress {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 4px 2px 2px 4px;
    background: var(--color-primary);
  }
}
