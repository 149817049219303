*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  color-scheme: light;
  --color-black: #000;
  --color-white: #fff;
  --rgb-gray-100: 212, 212, 212; // #d4d4d4
  --rgb-gray-200: 155, 155, 155; // #9b9b9b
  --rgb-gray-300: 104, 104, 105; // #686869
  --rgb-gray-400: 77, 77, 77; // #4d4d4d
  --rgb-gray-500: 51, 51, 51; // #333333
  --color-gray-100: rgb(var(--rgb-gray-100));
  --color-gray-200: rgb(var(--rgb-gray-200));
  --color-gray-300: rgb(var(--rgb-gray-300));
  --color-gray-400: rgb(var(--rgb-gray-400));
  --color-gray-500: rgb(var(--rgb-gray-500));

  --rgb-primary: 0, 35, 156;
  --color-primary: rgb(var(--rgb-primary));
  --color-primary-light: rgba(var(--rgb-primary), 0.56);
  --color-primary-extra-light: rgba(var(--rgb-primary), 0.1);

  --rgb-secondary: 228, 0, 43;
  --color-secondary: rgb(var(--rgb-secondary));

  --rgb-error: 228, 0, 43;
  --color-error: rgb(var(--rgb-error));

  --rgb-background: 242, 243, 245; // #f2f3f5
  --color-background: rgb(var(--rgb-background));
  --color-ticket-background: #f8f9fa;

  --color-blue: rgb(0, 35, 156); // #00239c
  --color-red: rgb(228, 0, 43);

  --color-mobit: #68b948;
  --color-delijn: #ffdd00;
  --color-blue-bike: #00A4E4;
  --color-velo: #f7b322;
  --color-mobit-disabled: #90b382;

  --color-text-default: var(--color-gray-500);
  --color-text-inverted: var(--color-white);
  --color-disabled-bg: var(--color-background);

  --color-status-grey: var(--color-gray-500);
  --color-status-blue: var(--color-blue);
  --color-status-green: #408b41;
  --color-status-red: var(--color-red);

  --font-family: Arial;
  --font-family-secondary: Arial;
  --font-family-numbers: Arial;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  color: var(--color-gray-500);
  font-family: var(--font-family);
}

/* force only portrait orientation as app is not designed for landscape */
@media screen and (orientation: landscape) and (min-aspect-ratio: 8/5) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

body {
  &.noScroll {
    overflow: hidden;
  }
}

#root {
  width: 100%;
  min-height: 100%;
}

a {
  color: var(--color-primary);
}

button {
  color: inherit;
}

.mobea__spinner {
  position: relative;
  display: inline-block;

  &__default {
    display: inline-block;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    border-radius: 50%;
    border: 8px solid var(--color-primary);
    border-top-color: transparent;
    animation: 1s spinner infinite linear;
  }

  &__button {
    width: 30px;
    height: 30px;
    margin-top: -12px;
    margin-bottom: -8px;
    margin-right: 8px;

    div {
      border-width: 3px !important;
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }
}

.mobea__animated-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
