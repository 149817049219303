.mobea__travel-pass {
  padding: 16px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.08);

  @media screen and (max-width: 360px) {
    padding: 16px 12px;
  }

  &__header {
    display: flex;
    height: 40px;

    &__type {
      width: 40px;
      height: 40px;
      color: white;
      background-color: #dddddd;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      &.mobea__transport-type__bike {
        background-color: var(--color-mobit);
      }

      &.mobea__transport-type__bus {
        background-color: var(--color-delijn);
      }

      &.mobea__transport-type__train {
        background-color: #0079c2;
      }

      &.mobea__transport-type__taxi {
        background-color: #000;
      }
    }

    &__logo {
      display: flex;
      align-items: center;

      > svg, > img {
        height: auto;
        width: 34px;

        &.mobea__verts {
          width: 48px;
        }
      }
    }
  }
}

.mobea__uber-ticket {
  .mobea__travel-pass__bottom-logos__delijn {
    height: 50%;
  }
}

.mobea__uber-ticket {
  .mobea__travel-pass__header__logo {
    width: 36px;
  }
}
