.mobea__disabled-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(black, 0.12);
  z-index: 10000;
  animation: fadeIn 0.5s;
}
