.mobea__uploader {
  &__previews {
    clear: both;
    overflow: auto;

    &__preview {
      display: inline-block;
      width: calc(50% - 16px);
      overflow: hidden;
      border-radius: 8px;
      position: relative;
      margin: 8px 8px 20px;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.48);
      background-color: white;
      color: var(--color-primary);

      &__spacer {
        width: 100%;
        padding-top: 100%;
        height: 0;
      }

      > img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        background-color: white;

        &.mobea__image__error {
          visibility: hidden;
        }
      }

      > svg {
        position: absolute;
        top: -10px;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
      }

      &__name {
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 12%;
        margin: 0;
        padding: 0 5%;
        font-size: 13px;
        line-height: 24px;
        font-weight: 500;
        display: flex;
        flex-direction: row;
        height: 20px;
        justify-content: center;

        > span {
          flex: 0 0 auto;
          display: inline-block;

          &:first-child {
            flex: 0 1 auto;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }

      &__remove.mobea__close-button {
        position: absolute;
        top: 4%;
        right: 4%;
        border-radius: 50%;
        box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.64);
        color: var(--color-gray-200);
        background-color: white;
        width: 20px;
        height: 20px;
        z-index: 20;
      }
    }

    &__preview__upload {
      border: 3px dashed var(--color-primary-light);
      box-shadow: unset;
      background-color: initial;

      .mobea__uploader__upload__icon {
        margin: 0;
      }

      &__files-text {
        line-height: 20px;
      }
    }
  }

  &__upload {
    width: calc(100% - 16px);
    border: 3px dashed var(--color-primary-light);
    border-radius: 8px;
    position: relative;
    margin: 8px 8px 30px;

    &__spacer {
      padding: 25%;
    }

    &__icon-text {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &__icon {
        color: var(--color-primary);
        height: 40px;
        width: 56px;
      }

      &__text {
        margin: 4px 0 0;
        text-align: center;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: var(--color-primary);
        right: 0;
      }
    }

    &__icon {
      position: absolute;
      top: 50%;
      left: 50%;
      color: var(--color-primary);
      height: 40px;
      width: 56px;
      transform: translate(-50%, -50%);
    }

    &__input {
      -webkit-appearance: none;
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 1;
    }
  }
}
