@import "styles/constants.scss";

.mobea-dashboard {
  @include fullscreen-page;
  background-color: var(--color-background);

  &__banner {
    text-align: center;
    transition: opacity 0.5s;

    .mobea__banner {
      display: inline-block;
      width: auto;
      height: 64px;
      padding: 18px 26px;

      &__text {
        line-height: 28px;
      }
    }
  }

  .mobea__plan-overview {
    transition: opacity 0.5s;
  }

  &.ticket-detail-visible {
    .mobea-dashboard__banner,
    .mobea__plan-overview {
      opacity: 0;
    }
  }
}
