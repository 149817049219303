.map-tutorial {
  &__icon {
    fill: var(--color-primary);

    .mobea__navigation__inactive {
      fill: var(--color-primary);
    }

    .mobea__navigation__active {
      display: none;
    }
  }

  @media screen and (max-width: 360px) {
    .mobea__info {
      padding: 32px 24px;
    }
  }
}
