.mobea__input-group {
  height: 51px;
  float: left;
  position: relative;
  padding-top: 14px;
  border-bottom: 1px solid var(--color-gray-100);
  transition: border-color 200ms;

  > label {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 13px;
    line-height: 24px;
    color: var(--color-gray-200);
    font-family: var(--font-family-secondary);
    font-weight: 500;
    height: 14px;
  }

  > input {
    width: 100%;
    height: 36px;
    border: 0 none;
    padding-top: 4px;
    line-height: 24px;
    font-size: 16px;
    font-family: var(--font-family-secondary);
    color: var(--color-gray-500);
    background-color: transparent;
    padding-left: 2px;
    opacity: 1;

    &::placeholder {
      color: var(--color-gray-200);
      opacity: 1;
    }

    &:focus {
      outline: 0 none;
    }
  }

  &.mobea__disabled {
    border-bottom: none;
    overflow: hidden;

    > input {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      pointer-events: none;
      color: var(--color-gray-300);
      -webkit-text-fill-color: var(--color-gray-300);
    }
  }

  &.mobea__focused {
    border-bottom: 2px solid var(--color-primary-light);

    label {
      color: var(--color-primary);
    }
  }

  &.mobea__errored {
    border-bottom: 2px solid var(--color-error);
    white-space: nowrap;

    label {
      color: var(--color-error);
    }
  }

  &__error {
    color: var(--color-error);
    font-family: var(--font-family-secondary);
    font-size: 13px;
    letter-spacing: 0;
    line-height: 13px;
    position: absolute;
    left: 0;
    bottom: -18px;
  }
}
