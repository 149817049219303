.mobea-active-tickets-list {
  margin: 0 8px;

  &__passes__active-pass-header {
    height: 28px;
    position: absolute;
    top: 32px;
    right: 16px;
    left: 16px;
    transition: opacity 0.5s;

    @media screen and (max-height: 620px) {
      margin-bottom: 16px;
    }

    > h1 {
      color: var(--color-primary);
      font-size: 22px;
      font-weight: 500;
      line-height: 28px;
      text-align: center;
      margin: 0;
      padding-left: 15px;

      @media screen and (max-height: 620px) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    &__back {
      position: absolute;
      left: -6px;
      top: 0;
      background: none;
      border: 0 none;
      color: var(--color-primary);
      display: inline-block;
      height: 100%;
      padding: 0px 2px 4px 0px;
      outline: 0 none;
    }

    &__info {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      padding: 0 2px 4px 2px;
      z-index: 5;

      > svg {
        height: 100%;
        width: auto;
      }
    }

    &.hidden {
      position: absolute;
      opacity: 0;
    }
  }

  &__passes__headline {
    margin: 40px 0 16px;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: var(--color-gray-300);
    font-weight: 600;
    transition: opacity 0.5s;

    &__count {
      font-family: var(--font-family-numbers);
    }

    &.hidden {
      position: absolute;
      opacity: 0;
    }

    /* narrow screen breaks validity period into 2 lines */
    @media screen and (max-width: 370px) {
      margin-top: 40px;
    }

    @media screen and (min-width: 550px) {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__passes__wrapper {
    transition: opacity 300ms ease-in;
    transition-delay: 1s;

    &.mobea__loading {
      opacity: 0.3;
    }
  }

  &__passes__spacer {
    transition: height 0.2s;
  }

  &__no-passes {
    display: flex;
    align-items: center;
    margin-bottom: 62px;

    &__icon {
      flex: 0 0 64px;
      height: 60px;
      margin-left: 8px;
      margin-right: 32px;
    }

    &__text {
      margin: 0;
      font-size: 14px;
      line-height: 24px;
      color: var(--color-gray-300);
      font-weight: 400;
    }
  }

  &__passes {
    position: absolute;
    left: 16px;
    right: 16px;

    @media screen and (min-width: 550px) {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (max-width: 360px) {
      left: 10px;
      right: 10px;
    }

    &__pass {
      position: absolute;
      left: -12px;
      right: -12px;
      transition: all 0.5s;
      padding: 12px;
      margin-top: -12px;

      &__multi {
        margin-left: -16px;
        margin-right: -16px;
      }
    }

    .mobea__travel-pass {
      width: 100%;

      /* compate fact that NMBS preview is smaller than others */
      &.mobea__nmbs-ticket:first-child {
        margin-bottom: 20px;
      }
    }
  }
}
